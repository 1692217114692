import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		eligibleForMedicaid: "",
		eligibleForInsuranceCarrier: "",
		eligibleForEmploymentHealthCare: "",
	},
	validation: Yup.object().shape({
		eligibleForMedicaid: Yup.string().required(requiredWarning),
		eligibleForInsuranceCarrier: Yup.string().required(requiredWarning),
		eligibleForEmploymentHealthCare: Yup.string().required(requiredWarning),
	}),
};

const GeneralExclusions1 = () => {
	const { errors, values } = useFormikContext<any>();
	const eligibleForMedicaid = values["eligibleForMedicaid"];
	const eligibleForInsuranceCarrier = values["eligibleForInsuranceCarrier"];
	const eligibleForEmploymentHealthCare =
		values["eligibleForEmploymentHealthCare"];

	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (eligibleForMedicaid === "Yes") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [eligibleForMedicaid]);

	useEffect(() => {
		if (eligibleForInsuranceCarrier === "Yes") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [eligibleForInsuranceCarrier]);

	useEffect(() => {
		if (eligibleForEmploymentHealthCare === "Yes") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [eligibleForEmploymentHealthCare]);

	return (
		<>
			<FormGrid className="flex-col">
				<Radio
					className="!max-w-[850px]"
					name="eligibleForMedicaid"
					label="I am eligible for full coverage Medicaid."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-[850px]"
					name="eligibleForInsuranceCarrier"
					label="I am eligible for coverage offered by an insurance carrier or the New Mexico Health Insurance Exchange (NMHIX)."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-[850px]"
					name="eligibleForEmploymentHealthCare"
					label="I have or am eligible for an employment-related group health plan or Tricare, either as myself or as a family member."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
			</FormGrid>

			<Modal
				modalLabel="Pool Eligibility Criteria"
				open={isModalOpen}
				setOpen={setIsModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your response may indicate that you are not eligible for coverage.
					However, you can continue to submit an application for review.
				</NoticeBanner>

				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsModalOpen(false)}
				>
					Continue Application <FaArrowRight />
				</Button>
			</Modal>
		</>
	);
};

export default GeneralExclusions1;
