import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Link,
} from "@react-pdf/renderer";
import { newbornFormValues } from "../FormSchema/formInitialValues";
import PdfPage, {
	displayFormattedDate,
	fieldStyles,
	PdfCheckbox,
	PdfField,
	pdfStyles,
} from "../../../components/PdfDownload/PdfTemplate";

export const newbornPdfStyles = StyleSheet.create({
	certification: {
		paddingHorizontal: 30,
	},
	signatureLabel: {
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		textAlign: "center",
		marginTop: 50,
		paddingHorizontal: 30,
	},
});

const NewbornPdf = (props: { formValues: typeof newbornFormValues }) => {
	const { formValues } = props;

	return (
		<Document>
			<PdfPage
				heading="Application for Coverage"
				heading2="Newborn"
				pageLabel="Page 1 of 1"
				revisionLabel=""
			>
				{/* <View
				style={{
					...pdfStyles.subHeader,
					...pdfStyles.section,
				}}
			>
				<Text style={{ ...pdfStyles.maxW70 }}>
					Newborn coverage for the first 31 days after birth is a benefit of the
					parent's Pool policy. Only current Pool enrollees are eligible for
					newborn coverage. At day 32 from the date of birth, the child's policy
					will be canceled, unless there is a qualifying event which would make
					the child eligible for the Pool. First month's premium must be
					included with the application.
				</Text>

				<View style={{ ...pdfStyles.address }}>
					<Text>P.O. Box 780548</Text>
					<Text>San Antonio, Tx 78278</Text>
					<Text>1-866-306-1882</Text>
					<Link style={pdfStyles.address} src="https://nmmip.org/">
						www.nmmip.org
					</Link>
				</View>
			</View> */}

				<View style={{ ...pdfStyles.section }}>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						Newborn coverage for the first 31 days after birth is a benefit of
						the parent's Pool policy. Only current Pool enrollees are eligible
						for newborn coverage. At day 32 from the date of birth, the child's
						policy will be canceled, unless there is a qualifying event which
						would make the child eligible for the Pool. First month's premium
						must be included with the application.
					</Text>

					<Text>
						If you have questions or need assistance completing this
						application, please contact{" "}
						<Link style={pdfStyles.link} src="tel:+18663061882">
							1-866-306-1882
						</Link>
					</Text>
					<Text>
						or email{" "}
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
						.
					</Text>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 1: Applicant Information
					</Text>

					{/* Newborn info */}
					<PdfField label="Newborn's First Name" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.newborn.firstName}</Text>
					</PdfField>
					<PdfField label="Newborn's Last Name" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.newborn.lastName}</Text>
					</PdfField>
					<PdfField label="MI" style={{ ...pdfStyles.w10 }}>
						<Text>{formValues.newborn.middleInitial}</Text>
					</PdfField>
					<PdfField label="Gender" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.newborn.gender}</Text>
					</PdfField>
					<PdfField
						label="Birth Date (MM/DD/YYYY)"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderRight }}
					>
						{displayFormattedDate(formValues.newborn.birthDate)}
					</PdfField>
					{/* Pool Enrollee */}
					<PdfField
						label="Pool Enrollee's Last Name"
						style={{ ...pdfStyles.w20 }}
					>
						<Text>{formValues.enrollee.lastName}</Text>
					</PdfField>
					<PdfField
						label="Pool Enrollee's First Name"
						style={{ ...pdfStyles.w20 }}
					>
						<Text>{formValues.enrollee.firstName}</Text>
					</PdfField>
					<PdfField label="MI" style={{ ...pdfStyles.w10 }}>
						<Text>{formValues.enrollee.middleInitial}</Text>
					</PdfField>
					<PdfField
						label="Pool Enrollee's Pool ID Number"
						style={{ ...pdfStyles.w25 }}
					>
						<Text>{formValues.enrollee.poolID}</Text>
					</PdfField>
					<PdfField
						label="Pool Enrollee's Birth Date"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderRight }}
					>
						{displayFormattedDate(formValues.enrollee.birthDate)}
					</PdfField>
					{/* residence address */}
					<PdfField
						label="Residence Address (Physical address required)"
						style={{ ...pdfStyles.w50 }}
					>
						<Text>{formValues.residence.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.residence.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.residence.zipCode}</Text>
					</PdfField>
					{/* mailing address */}
					<PdfField label="Mailing Address" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.mailing.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.mailing.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.mailing.zipCode}</Text>
					</PdfField>
					<PdfField
						label="The baby will be a resident of the state of New Mexico."
						style={{ ...pdfStyles.wFull, ...fieldStyles.borderRight }}
						flexRow
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Yes"
								isChecked={formValues.isNewbornResidentOfNM === "Yes"}
							/>
							<PdfCheckbox
								label="No"
								isChecked={formValues.isNewbornResidentOfNM === "No"}
							/>
						</View>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text
							style={{
								...pdfStyles.wFull,
								...pdfStyles.fontBold,
							}}
						>
							I understand the first month's premium must be included with the
							application.
						</Text>
					</PdfField>
				</View>

				<View
					style={{ ...pdfStyles.section, ...newbornPdfStyles.certification }}
				>
					<Text
						style={{
							...pdfStyles.wFull,
						}}
					>
						I certify that the foregoing statements are true and accurate. I
						understand that no coverage will be effective until the full initial
						premium is paid and this application has been approved by the Pool
						Administrator. I understand that this policy will automatically be
						canceled 31 days after the baby's birth unless the baby has a
						qualifying event which makes him/her eligible for the Pool. I
						further understand that this coverage is a benefit of my policy with
						the Pool, and I certify that I am the biological or adoptive parent
						or legal guardian of this child.
					</Text>
				</View>

				<View
					style={{ ...pdfStyles.section, ...newbornPdfStyles.signatureLabel }}
				>
					<View style={pdfStyles.w50}>
						<View style={{ ...pdfStyles.divider, ...pdfStyles.wFull }} />
						<Text>Signature of Pool Enrollee Named Above</Text>
					</View>
					<View style={pdfStyles.w25}>
						<View style={{ ...pdfStyles.divider, ...pdfStyles.wFull }} />
						<Text>Relationship to applicant</Text>
					</View>
					<View style={pdfStyles.w25}>
						<View style={{ ...pdfStyles.divider, ...pdfStyles.wFull }} />
						<Text>Date</Text>
					</View>
				</View>

				<View style={{ ...pdfStyles.contactInfo }}>
					<Text>New Mexico Medical Insurance Pool (NMMIP)</Text>
					<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
						<Text style={{ ...pdfStyles.fontBold }}>Mail:</Text>
						<Text>P.O. Box 780548, San Antonio, Tx 78278</Text>
					</View>

					<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
						<Text style={{ ...pdfStyles.fontBold }}>Email:</Text>
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
					</View>

					<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
						<Text style={{ ...pdfStyles.fontBold }}>Fax:</Text>{" "}
						<Text>210-239-8449</Text>
					</View>
				</View>

				{/* <View
				style={{
					...pdfStyles.section,
					...pdfStyles.wFull,
					...pdfStyles.flexRow,
					...pdfStyles.mt30,
				}}
			>
				<View
					style={{
						...pdfStyles.w50,
						...pdfStyles.textCenter,
					}}
				>
					<Text style={pdfStyles.fontSemiBold}>
						State Pool requires first month's premium to be paid.
					</Text>
					<Text style={pdfStyles.fontSemiBold}>Make check payable to:</Text>
					<Text>New Mexico Medical Insurance Pool</Text>
				</View>
				<View
					style={{
						...pdfStyles.w50,
						...pdfStyles.textCenter,
					}}
				>
					<Text style={pdfStyles.fontSemiBold}>
						Mail complete application and premium check to:
					</Text>

					<Text>New Mexico Medical Insurance Pool</Text>
					<Text>P.O. Box 780548</Text>
					<Text>San Antonio, Tx 78278</Text>
				</View>
			</View> */}
			</PdfPage>
		</Document>
	);
};

export default NewbornPdf;
