import React from "react";
import styled, { css } from "styled-components";

export const StyledFormContainer = styled.div`
	${(p) => css`
		background: ${p.theme.colorBackgroundLightBlue};

		h1,
		h2 {
			color: ${p.theme.colorPrimary};
		}

		h2 {
			margin-top: 10px;
		}
	`};
`;

const FormContainer = (props: { children: React.ReactNode }) => {
	return (
		<StyledFormContainer className="flex flex-col gap-[30px] mx-auto max-w-[1440px] p-[3rem]">
			{props.children}
		</StyledFormContainer>
	);
};

export default FormContainer;
