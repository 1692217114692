import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css, useTheme } from "styled-components";
import {
	countyOptions,
	householdSizeOptions,
	incomeThresholdMap,
	povertyGuidelineOptions,
} from "../FormSchema/formInitialValues";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import StyledList from "../../../components/Lists/Lists";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		filedForFederalTax: "",
		notRequiredToFileFederalTax: "",
		hasIncomeChanged: "",
	},
	validation: Yup.object().shape({
		filedForFederalTax: Yup.string().required(requiredWarning),
		notRequiredToFileFederalTax: Yup.string().required(requiredWarning),
		hasIncomeChanged: Yup.string().required(requiredWarning),
	}),
};

const OtherIncome = () => {
	const { values, setFieldValue } = useFormikContext<any>();

	return (
		<>
			<FormGrid className="flex-col">
				<Radio
					className="!max-w-full"
					name="filedForFederalTax"
					label="Did you file Federal Income Tax forms for the prior year?"
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-full"
					name="notRequiredToFileFederalTax"
					label="Did any adults in your household have income  (except as excluded in the Household Size & Income Verification section) and was not required to file a Federal Income Tax form?"
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-full"
					name="hasIncomeChanged"
					label="Is your current income different from your most recent tax filing?"
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
			</FormGrid>
		</>
	);
};

export default OtherIncome;
