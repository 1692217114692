import { FieldType } from "./fieldTypes";
import { Asterisk, Error, getPlaceholder, StyledFieldWrapper } from "./Field";
import { Field, useField, useFormikContext } from "formik";
import { useEffect } from "react";
import StyledLegend from "../Legend/Legend";

const Dropdown = (props: DropdownProps) => {
	const { values, setFieldValue } = useFormikContext<any>();
	const { name, label, options, required, className, value, placeholder } =
		props;
	const [field, meta, helpers] = useField(props);
	const hasvalue =
		field.value !== null && field.value !== undefined && field.value !== "";
	const hasErr = meta.touched && meta.error ? true : false;

	useEffect(() => {
		console.log(values[name]);
	}, [values[name]]);

	return (
		<div className={`${className ?? ""} flex flex-col`}>
			{placeholder && label && (
				<StyledLegend>
					{label}
					{required && <Asterisk />}
				</StyledLegend>
			)}

			<StyledFieldWrapper haserror={hasErr}>
				<span className="caret" />
				<Field
					className="dropdown"
					as="select"
					name={name}
					label={label}
					required={required}
				>
					<option value="">
						{getPlaceholder(placeholder || label, required)}
					</option>
					{options.map((option) => (
						<option
							key={`${name}.${option.label}`}
							value={option.value !== undefined ? option.value : option.label}
							selected={
								values[name] ===
								(option.value !== undefined ? option.value : option.label)
							}
						>
							{option.label}
						</option>
					))}
				</Field>
			</StyledFieldWrapper>
			<Error touched={meta.touched} error={meta.error} />
		</div>
	);
};

export default Dropdown;

interface DropdownProps extends FieldType {
	className?: string;
	options: SelectOption[];
	placeholder?: string;
}

export interface SelectOption {
	label: string;
	value?: any;
}
