import React from "react";
import logo from "../../assets/NMMIP_logo.webp";

const Header: React.FC = () => {
	return (
		<div id="form-header" className="flex items-center w-full py-6">
			<img
				src={logo}
				alt="NMMIP Logo"
				className="w-[185px] cursor-pointer"
				onClick={() => (window.location.href = "https://nmmip.org/")}
			/>

			<div id="download-link-container" className="ml-auto"></div>
		</div>
	);
};

export default Header;
