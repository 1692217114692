import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { FieldArray, useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css } from "styled-components";
import {
	countyOptions,
	householdSizeOptions,
	incomeThresholdMap,
	povertyGuidelineOptions,
} from "../FormSchema/formInitialValues";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import { CloseIcon } from "../../../components/Icons/Close";

const requiredWarning = "This field is required.";

const newHouseholdMember = {
	name: "",
	relationship: "",
	birthDate: "",
};

export const schema = {
	values: {
		household: [
			newHouseholdMember,
			newHouseholdMember,
			newHouseholdMember,
			newHouseholdMember,
			newHouseholdMember,
			newHouseholdMember,
		],
	},
	validation: Yup.object().shape({
		household: Yup.array()
			// .of(
			// 	Yup.object({
			// 		name: Yup.string().required(requiredWarning),
			// 		relationship: Yup.string().required(requiredWarning),
			// 		birthDate: Yup.string().required(requiredWarning),
			// 	})
			// )
			.test({
				name: "oneRequired",
				test(value, ctx) {
					const { household } = ctx.parent;

					if (household && household.length > 0) {
						const firstHouseholdMember = household[0];

						if (!firstHouseholdMember.name)
							return ctx.createError({
								path: "household[0].name",
								message: requiredWarning,
							});

						if (!firstHouseholdMember.relationship)
							return ctx.createError({
								path: "household[0].relationship",
								message: requiredWarning,
							});

						if (!firstHouseholdMember.birthDate)
							return ctx.createError({
								path: "household[0].birthDate",
								message: requiredWarning,
							});
					}

					return true;
				},
			})
			.min(1, "At least one household member is required."),
	}),
};

const Household = () => {
	const { values, setFieldValue } = useFormikContext<any>();

	return (
		<>
			<BlueLegendHeading className="text-[24px]">
				List all the people in your household.
			</BlueLegendHeading>

			<FieldArray name="household">
				{({ push, remove }) => (
					<>
						{values.household && values.household.length > 0 ? (
							values.household.map((householdMember: any, index: number) => (
								<FormGrid key={`household.${index}.key`}>
									<TextField
										type="text"
										name={`household.${index}.name`}
										label="Name"
										required
									/>
									<TextField
										type="text"
										name={`household.${index}.relationship`}
										label="Relationship to Applicant"
										required
									/>
									<DateField
										name={`household.${index}.birthDate`}
										label="Birth Date"
										placeholder="Birth Date (MM/DD/YYYY)"
										required
									/>
									<CloseIcon
										className="self-center"
										onClick={() => remove(index)}
									/>
								</FormGrid>
							))
						) : (
							<></>
						)}

						<Button
							type="button"
							onClick={() => push(newHouseholdMember)}
							buttonstyle={ColorStyle.Secondary}
						>
							Add Additional Household Members
						</Button>
					</>
				)}
			</FieldArray>
		</>
	);
};

export default Household;
