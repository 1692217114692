import { FormikProvider, useFormik, useFormikContext } from "formik";
import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Button, { ColorStyle } from "../../../components/Button/Button";
import { medicareFormValues, medicareFormSchema } from "./formInitialValues";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { steps, renderFormStep } from "./Steps";
import { borders } from "../../../theme";
import { objectToArray } from "../../../utils/objectToArray";
import { FaExclamationTriangle } from "react-icons/fa";
import BrokerPopup from "../../../components/BrokerPopup/BrokerPopup";
import ProgressIndicator from "../../../components/ProgressIndictaor/ProgressIndicator";
import Modal from "../../../components/Modal/Modal";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import { PaymentTypes } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import { useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import PdfDownload from "../../../components/PdfDownload/PdfDownload";
import MedicarePdf from "../Pdf/MedicarePdf";
import { PDFViewer } from "@react-pdf/renderer";
import { pdfStyles } from "../../../components/PdfDownload/PdfTemplate";

export const FormSeparator = styled.hr`
	height: 5px;
	width: 100%;
	background: linear-gradient(90deg, #df7285, #e68d9d);
	/* margin: 0 0 30px 0; */
	border-radius: 5px;
`;

export const ErrorSummary = styled.div`
	${(p) => css`
		display: flex;
		flex-direction: column;
		background: #ffeff2;
		width: 100%;
		padding: 24px;
		gap: 10px;
		border-radius: ${borders.fields.radius};
		border: solid 2px ${p.theme.colorSecondary};
		color: ${p.theme.colorSecondary};

		h2 {
			color: ${p.theme.colorSecondary} !important;
		}
	`};
`;

const MedicareForm = () => {
	const [activeStep, setActiveStep] = useState(0);
	const isFirstStep = activeStep === 0;
	const isLastStep = activeStep === steps.length - 2;
	const isSubmissionPage = activeStep === steps.length - 1;
	const [showErrors, setShowErrors] = useState(false);
	const scrollRef = useRef<any>(null);
	const navigate = useNavigate();
	const [isMoneyOrderModalOpen, setIsMoneyOrderModalOpen] = useState(false);
	const { state } = useLocation();
	const downloadLinkContainer = document.querySelector(
		"#download-link-container"
	);

	const formikProps = useFormik({
		initialValues: medicareFormValues,
		validationSchema: medicareFormSchema[activeStep],
		validateOnChange: true,
		onSubmit: async (values, actions) => {
			if (isLastStep) {
				// TODO: api call to save the form
				console.log("entire form payload", values);

				if (formikProps.values.applyingForLipp === "Yes") {
					handleRedirectToLipp();
				} else setActiveStep(activeStep + 1);
			} else {
				if (
					activeStep === 8 &&
					formikProps.values.paymentType === PaymentTypes[0].label
				) {
					setIsMoneyOrderModalOpen(true);
				}

				if (activeStep === 5) {
					// skip insurance agent section if applicant is not insurance agent
					values.isInsuranceAgent === "Yes"
						? setActiveStep(activeStep + 1)
						: setActiveStep(activeStep + 2);
				} else setActiveStep(activeStep + 1);

				actions.setTouched({});
				actions.setSubmitting(false);
			}

			window.scrollTo({ top: 0, behavior: "smooth" });
		},
	});

	const handleRedirectToLipp = () => {
		navigate("/lipp-form/medicare-carve-out", {
			state: {
				firstName: formikProps.values.applicant.firstName,
				lastName: formikProps.values.applicant.lastName,
				middleInitial: formikProps.values.applicant.middleInitial,
				address: formikProps.values.residence.address,
				city: formikProps.values.residence.city,
				residenceState: formikProps.values.residence.state,
				zip: formikProps.values.residence.zipCode,
				coverageStartMonth: formikProps.values.coverageStartMonth,
				county: formikProps.values.county,
				householdSize: formikProps.values.householdSize,
				annualIncomeUpperThreshold: formikProps.values.householdAnnualIncome,
				deductible: formikProps.values.deductible,
			},
		});
	};

	const residenceState = formikProps.values.residence.state;

	const handlePrev = () => {
		if (activeStep === 7 && formikProps.values.isInsuranceAgent === "No") {
			// skip insurance agent section if applicant is not insurance agent
			setActiveStep(activeStep - 2);
		} else setActiveStep(activeStep - 1);

		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		console.log("formikProps.values", formikProps.values);
	}, [formikProps.values]);

	useEffect(() => {
		console.log("formikProps.errors", formikProps.errors);
	}, [formikProps.errors]);

	const errorsArr =
		Object.entries(formikProps.errors).length > 0 &&
		objectToArray(formikProps.errors);

	const handleShowErrors = () => {
		setShowErrors(true);
	};

	useEffect(() => {
		setShowErrors(false);
	}, [activeStep]);

	useEffect(() => {
		if (showErrors && scrollRef.current) {
			const elementPosition =
				scrollRef.current.getBoundingClientRect().top + window.scrollY;
			const offset = 100;

			// Scroll to the position with the offset
			window.scrollTo({
				top: elementPosition - offset,
				behavior: "smooth",
			});
		}
	}, [showErrors]);

	useEffect(() => {
		if (state) {
			const { preferredLanguage, isHearingImpaired } = state;

			if (preferredLanguage)
				formikProps.setFieldValue("preferredLanguage", preferredLanguage);

			if (isHearingImpaired)
				formikProps.setFieldValue("isHearingImpaired", isHearingImpaired);
		}
	}, [state]);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault();
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	return (
		<>
			{downloadLinkContainer &&
				activeStep !== 0 &&
				createPortal(
					<PdfDownload
						pdf={<MedicarePdf formValues={formikProps.values} />}
						fileName="medicare_application.pdf"
					/>,
					downloadLinkContainer
				)}

			{errorsArr && showErrors && (
				<ErrorSummary ref={scrollRef}>
					<h2 className="flex items-center gap-[1rem]">
						<FaExclamationTriangle />
						There was a problem with your submission. Please review the required
						fields below.
					</h2>

					{errorsArr.map((obj, index) => {
						const [[key, value]] = Object.entries(obj);
						return (
							<p>
								{key}: {value}
							</p>
						);
					})}
				</ErrorSummary>
			)}
			<FormikProvider value={formikProps}>
				<div className="flex justify-between items-center gap-[1rem] flex-wrap md:flex-nowrap">
					<h1 className="whitespace-pre-wrap">{steps[activeStep].heading}</h1>
					{!isSubmissionPage && (
						<ProgressIndicator
							activeStep={activeStep}
							totalSteps={steps.length - 2}
						/>
					)}
				</div>

				<FormSeparator />
				{steps[activeStep].subHeading && <p>{steps[activeStep].subHeading}</p>}

				{renderFormStep(activeStep)}
				{!isSubmissionPage && (
					<div className="flex gap-[1rem] mt-[32px]">
						{!isFirstStep && (
							<Button type="button" onClick={handlePrev}>
								<FaArrowLeft />
								Back
							</Button>
						)}

						<Button
							className="font-semibold"
							type="submit"
							disabled={
								formikProps.isSubmitting ||
								(residenceState && activeStep === 1
									? residenceState !== "New Mexico"
									: false)
							}
							onClick={() => {
								handleShowErrors();
								formikProps.submitForm();
							}}
							buttonstyle={
								isLastStep ? ColorStyle.Secondary : ColorStyle.Primary
							}
						>
							{isFirstStep
								? "Start Application"
								: isLastStep && formikProps.values.applyingForLipp === "Yes"
								? "Submit and Start LIPP Application"
								: isLastStep
								? "Submit Application"
								: "Next"}
							<FaArrowRight />
						</Button>
					</div>
				)}
			</FormikProvider>
			<BrokerPopup isOpen={!isSubmissionPage} />

			<Modal
				modalLabel="Money Order or Check Premium Payment"
				open={isMoneyOrderModalOpen}
				setOpen={setIsMoneyOrderModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your application will be marked as PENDING until your money order or
					check payment of first premium is received.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsMoneyOrderModalOpen(false)}
				>
					Continue Application
					<FaArrowRight />
				</Button>
			</Modal>

			{/* {activeStep !== 0 && (
				<div key={String(pdfStyles)}>
					<PDFViewer width="1000" height="1000" showToolbar>
						<MedicarePdf formValues={formikProps.values} />
					</PDFViewer>
				</div>
			)} */}
		</>
	);
};

export default MedicareForm;
