import { FormikProvider, useFormik, useFormikContext } from "formik";
import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Button, { ColorStyle } from "../../../components/Button/Button";
import {
	healthCareFormValues,
	healthCareFormSchema,
	PaymentTypes,
	schemas,
} from "./formInitialValues";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { steps, renderFormStep } from "./Steps";
import { borders } from "../../../theme";
import { objectToArray } from "../../../utils/objectToArray";
import { FaExclamationTriangle } from "react-icons/fa";
import BrokerPopup from "../../../components/BrokerPopup/BrokerPopup";
import ProgressIndicator from "../../../components/ProgressIndictaor/ProgressIndicator";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal/Modal";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import { useFormContext } from "../../../components/FormContext/FormContext";
import axios from "axios";
import { createPortal } from "react-dom";
import PdfDownload from "../../../components/PdfDownload/PdfDownload";
import { PDFViewer } from "@react-pdf/renderer";
import { pdfStyles } from "../../../components/PdfDownload/PdfTemplate";
import HealthCareCoveragePdf from "../Pdf/HealthCareCoveragePdf";

export const FormSeparator = styled.hr`
	height: 5px;
	width: 100%;
	background: linear-gradient(90deg, #df7285, #e68d9d);
	/* margin: 0 0 30px 0; */
	border-radius: 5px;
`;

export const ErrorSummary = styled.div`
	${(p) => css`
		display: flex;
		flex-direction: column;
		background: #ffeff2;
		width: 100%;
		padding: 24px;
		gap: 10px;
		border-radius: ${borders.fields.radius};
		border: solid 2px ${p.theme.colorSecondary};
		color: ${p.theme.colorSecondary};

		h2 {
			color: ${p.theme.colorSecondary} !important;
		}
	`};
`;

const HealthCareForm = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [initialFormValues, setInitialFormValues] =
		useState(healthCareFormValues);
	const [loading, setLoading] = useState(true);
	const isFirstStep = activeStep === 0;
	const isLastStep = activeStep === steps.length - 2;
	const isSubmissionPage = activeStep === steps.length - 1;
	const [showErrors, setShowErrors] = useState(false);
	const scrollRef = useRef<any>(null);
	const navigate = useNavigate();
	const [isMoneyOrderModalOpen, setIsMoneyOrderModalOpen] = useState(false);
	const { contactInfo, verificationMethod, formId } = useFormContext();
	const downloadLinkContainer = document.querySelector(
		"#download-link-container"
	);

	useEffect(() => {
		const fetchFormData = async () => {
			try {
				const response = await axios.get(
					`/api/forms/HealthCareCoverageForm/${formId}`
				);

				const fetchedData = response.data;
				console.log("Fetched form data on frontend: ", fetchedData);

				// Flatten the fetched data
				const flattenedFetchedData = flattenFetchedData(fetchedData);

				// Merge the flattened data with the default initial values
				const mergedValues = {
					...healthCareFormValues, // Default initial values
					...flattenedFetchedData, // Override with fetched data
				};

				// Update Formik's values by resetting them with the merged values
				formikProps.resetForm({ values: mergedValues });
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch form data:", error);
				setLoading(false);
			}
		};

		if (formId) {
			fetchFormData();
		} else {
			setLoading(false); // No formId means no data to fetch
		}
	}, [formId]);

	const flattenFetchedData = (fetchedData: any) => {
		let flattenedData: any = {};

		// Loop through the keys of fetchedData (e.g., hipaaSchema, startSchema, etc.)
		Object.keys(fetchedData).forEach((schemaKey) => {
			const schemaData = fetchedData[schemaKey];

			// If the schema contains nested objects (like "applicant" or "residence"), flatten them
			if (typeof schemaData === "object" && !Array.isArray(schemaData)) {
				Object.keys(schemaData).forEach((innerKey) => {
					if (typeof schemaData[innerKey] === "object") {
						// Merge deep nested objects like "applicant" or "preAuthorizedPaymentInfo"
						flattenedData = { ...flattenedData, ...schemaData };
					} else {
						// Otherwise, just assign the inner values directly
						flattenedData[innerKey] = schemaData[innerKey];
					}
				});
			} else {
				// If no nested objects, just assign it directly
				flattenedData = { ...flattenedData, ...schemaData };
			}
		});

		return flattenedData;
	};

	useEffect(() => {
		console.log("Contact Info: ", contactInfo);
		console.log("Verification Method: ", verificationMethod);
		console.log("Id: ", formId);
	}, [contactInfo, verificationMethod, formId]);

	// Save the current section of the form based on active step
	const handleSaveForm = async (values: any) => {
		const formSection = getFormSectionByStep(activeStep, values); // Get relevant section based on active step
		console.log("Form Section being saved: ", formSection);
		try {
			await axios.post(
				`/api/forms/update/HealthCareCoverageForm/${formId}`,
				formSection
			); // Save the current form section
			console.log("Form saved successfully");
		} catch (error) {
			console.error("Failed to save form data:", error);
		}
	};

	const getFormSectionByStep = (step: number, values: any) => {
		const stepName = Object.keys(schemas)[step - 1];

		// TypeScript doesn't know that `schemas[stepName]` will have the correct type, so we need to assert it
		const schema = schemas[stepName as keyof typeof schemas];
		console.log("Schema?: ", schema);
		console.log("Step Name: ", stepName);

		if (schema && schema.values) {
			const sectionValues: Record<string, any> = {};

			// Extract only the fields that are part of this schema step
			for (const key in schema.values) {
				if (Object.prototype.hasOwnProperty.call(schema.values, key)) {
					sectionValues[key] = values[key]; // Grab the values from formikProps.values
				}
			}

			return { [stepName]: sectionValues };
		}

		return {}; // Return empty object if no schema found
	};

	const formikProps = useFormik({
		initialValues: healthCareFormValues,
		enableReinitialize: true,
		validationSchema: healthCareFormSchema[activeStep],
		validateOnChange: true,
		onSubmit: async (values, actions) => {
			await handleSaveForm(values); // Save the form data before moving to the next step
			console.log("Active Step: ", activeStep);
			console.log("Values being sent: ", values);
			if (isLastStep) {
				// TODO: api call to save the entire form at the last step
				console.log("entire form payload", values);

				if (formikProps.values.applyingForLipp === "Yes") {
					handleRedirectToLipp();
				} else setActiveStep(activeStep + 1);
			} else {
				if (
					activeStep === 16 &&
					formikProps.values.paymentType === PaymentTypes[0].label
				) {
					setIsMoneyOrderModalOpen(true);
				}

				if (activeStep === 2 && values.isOver65 === "Yes") {
					navigate("/medicare-form/", {
						state: {
							preferredLanguage: formikProps.values.preferredLanguage,
							isHearingImpaired: formikProps.values.isHearingImpaired,
						},
					});
				} else if (activeStep === 3 && values.isNewborn === "Yes") {
					navigate("/newborn-form/");
				} else if (activeStep === 14) {
					// skip section 5 if applicant is not insurance agent
					values.isInsuranceAgent === "Yes"
						? setActiveStep(activeStep + 1)
						: setActiveStep(activeStep + 2);
				} else setActiveStep(activeStep + 1);

				actions.setTouched({});
				actions.setSubmitting(false);
			}

			window.scrollTo({ top: 0, behavior: "smooth" });
		},
	});

	const handleRedirectToLipp = () => {
		navigate("/lipp-form/", {
			state: {
				firstName: formikProps.values.applicant.firstName,
				lastName: formikProps.values.applicant.lastName,
				middleInitial: formikProps.values.applicant.middleInitial,
				address: formikProps.values.residence.address,
				city: formikProps.values.residence.city,
				residenceState: formikProps.values.residence.state,
				zip: formikProps.values.residence.zipCode,
				coverageStartMonth: formikProps.values.coverageStartMonth,
				county: formikProps.values.county,
				householdSize: formikProps.values.householdSize,
				annualIncomeUpperThreshold: formikProps.values.householdAnnualIncome,
				deductible: formikProps.values.deductible,
			},
		});
	};

	const residenceState = formikProps.values.residence.state;

	const handlePrev = async () => {
		// Ensure the form section is validated and saved before navigating back
		// await formikProps.submitForm();

		if (activeStep === 16 && formikProps.values.isInsuranceAgent === "No") {
			// skip section 5 if applicant previously answered "No" for insurance agent
			setActiveStep(activeStep - 2);
		} else setActiveStep(activeStep - 1);

		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		console.log("formikProps.values", formikProps.values);
	}, [formikProps.values]);

	useEffect(() => {
		console.log("formikProps.errors", formikProps.errors);
	}, [formikProps.errors]);

	const errorsArr =
		Object.entries(formikProps.errors).length > 0 &&
		objectToArray(formikProps.errors);

	const handleShowErrors = () => {
		setShowErrors(true);
	};

	useEffect(() => {
		setShowErrors(false);
	}, [activeStep]);

	useEffect(() => {
		if (showErrors && scrollRef.current) {
			const elementPosition =
				scrollRef.current.getBoundingClientRect().top + window.scrollY;
			const offset = 100;

			// Scroll to the position with the offset
			window.scrollTo({
				top: elementPosition - offset,
				behavior: "smooth",
			});
		}
	}, [showErrors]);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault();
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	return (
		<>
			{downloadLinkContainer &&
				activeStep !== 0 &&
				createPortal(
					<PdfDownload
						pdf={<HealthCareCoveragePdf formValues={formikProps.values} />}
						fileName="application_for_coverage.pdf"
					/>,
					downloadLinkContainer
				)}
			{/* {activeStep !== 0 && (
				<div key={String(pdfStyles)}>
					<PDFViewer width="1000" height="1000" showToolbar>
						<HealthCareCoveragePdf formValues={formikProps.values} />
					</PDFViewer>
				</div>
			)} */}

			{errorsArr && showErrors && (
				<ErrorSummary ref={scrollRef}>
					<h2 className="flex items-center gap-[1rem]">
						<FaExclamationTriangle />
						There was a problem with your submission. Please review the required
						fields below.
					</h2>

					{errorsArr.map((obj, index) => {
						const [[key, value]] = Object.entries(obj);
						return (
							<p>
								{key}: {value}
							</p>
						);
					})}
				</ErrorSummary>
			)}
			<FormikProvider value={formikProps}>
				<div className="flex justify-between items-center gap-[1rem] flex-wrap md:flex-nowrap">
					<h1 className="whitespace-pre-wrap">{steps[activeStep].heading}</h1>
					{!isSubmissionPage && (
						<ProgressIndicator
							activeStep={activeStep}
							totalSteps={steps.length - 2}
						/>
					)}
				</div>

				<FormSeparator />
				{steps[activeStep].subHeading && <p>{steps[activeStep].subHeading}</p>}

				{renderFormStep(activeStep)}
				{!isSubmissionPage && (
					<div className="flex gap-[1rem] mt-[32px]">
						{!isFirstStep && (
							<Button type="button" onClick={handlePrev}>
								<FaArrowLeft />
								Back
							</Button>
						)}

						<Button
							className="font-semibold"
							type="submit"
							disabled={
								formikProps.isSubmitting ||
								(residenceState && activeStep === 5
									? residenceState !== "New Mexico"
									: false)
							}
							onClick={() => {
								handleShowErrors();
								formikProps.submitForm();
							}}
							buttonstyle={
								isLastStep ? ColorStyle.Secondary : ColorStyle.Primary
							}
						>
							{isFirstStep
								? "Start Application"
								: isLastStep && formikProps.values.applyingForLipp === "Yes"
								? "Submit and Start LIPP Application"
								: isLastStep
								? "Submit Application"
								: "Next"}
							<FaArrowRight />
						</Button>
					</div>
				)}
			</FormikProvider>
			<BrokerPopup isOpen={!isSubmissionPage} />

			<Modal
				modalLabel="Money Order or Check Premium Payment"
				open={isMoneyOrderModalOpen}
				setOpen={setIsMoneyOrderModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your application will be marked as PENDING until your money order or
					check payment of first premium is received.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsMoneyOrderModalOpen(false)}
				>
					Continue Application
					<FaArrowRight />
				</Button>
			</Modal>
		</>
	);
};

export default HealthCareForm;
