import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
  UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { USStates } from "../FormSchema/formInitialValues";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    residence: {
      address: "",
      city: "",
      state: "New Mexico",
      zipCode: "",
      county: "",
    },
    mailing: {
      address: "",
      city: "",
      state: "",
      zipCode: "",
      county: "",
    },
  },
  validation: Yup.object().shape({
    residence: Yup.object().shape({
      address: Yup.string().required(requiredWarning),
      city: Yup.string().required(requiredWarning),
      state: Yup.string()
        .oneOf(["New Mexico"], "You must be a New Mexico resident to apply.")
        .required(requiredWarning),
      zipCode: Yup.string().required(requiredWarning),
      county: Yup.string().required(requiredWarning),
    }),
  }),
};

const ApplicantInfo = () => {
  const { values } = useFormikContext<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const residenceState = values["residence"]["state"];
  const { formId } = useFormContext(); // Get formId from context

  useEffect(() => {
    if (residenceState && residenceState !== "New Mexico") {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [residenceState]);

  return (
    <>
      <h2>Address Information</h2>
      <FormGrid>
        <TextField
          className="!basis-[400px]"
          type="text"
          name="residence.address"
          label="Residence Address (Physical address required)"
          required
          autoComplete="street-address"
        />
        <TextField
          className="!max-w-[200px]"
          type="text"
          name="residence.city"
          label="City"
          required
        />
        <Dropdown
          className="!max-w-[200px]"
          name="residence.state"
          label="State"
          options={USStates}
          required
        />
        <TextField
          className="!max-w-[200px]"
          type="text"
          name="residence.zipCode"
          label="Zip Code"
          required
          maxLength={5}
        />
        <TextField
          className="!max-w-[200px]"
          type="text"
          name="residence.county"
          label="County"
          required
        />
      </FormGrid>

      <FormGrid>
        <TextField
          className="!basis-[400px]"
          type="text"
          name="mailing.address"
          label="Mailing Address (if different than Residence)"
          autoComplete="off"
        />
        <TextField
          type="text"
          name="mailing.city"
          label="City"
          autoComplete="off"
        />
        <Dropdown name="mailing.state" label="State" options={USStates} />
        <TextField
          type="text"
          name="mailing.zipCode"
          label="Zip Code"
          maxLength={5}
          autoComplete="off"
        />
        <TextField
          type="text"
          name="mailing.county"
          label="County"
          autoComplete="off"
        />
      </FormGrid>

      <Modal
        modalLabel="Proof of Residency Documentation"
        buttonLabel="Upload Documentation"
      >
        <NoticeBanner bannerstyle={ColorStyle.Primary}>
          You have indicated that you are a resident of the State of New Mexico.
          Please provide Proof of Residency Documentation (lease agreement,
          mortgage statement, utility bill, or other document).
        </NoticeBanner>
        <NoticeBanner bannerstyle={ColorStyle.Secondary}>
          If you don't have the documentation electronically, please mail it to
          NMMIP upon completing this application.
        </NoticeBanner>
        <FileUpload
          name="documentation.proofOfResidency"
          label="Upload Documentation"
          documentationType="proofOfResidency"
        />
      </Modal>

      <div className="max-w-[500px]">
        <UploadedFileList
          formId={formId}
          fieldName={"documentation.proofOfResidency"}
          documentationType="proofOfResidency"
        />
      </div>

      <Modal
        modalLabel="Residency Eligibility"
        open={isModalOpen}
        setOpen={setIsModalOpen}
      >
        <NoticeBanner bannerstyle={ColorStyle.Secondary}>
          You have indicated that you are <span className="underline">not</span>{" "}
          a resident of the State of New Mexico and therefore do not qualify for
          health insurance under the Pool's eligbility guidelines. You{" "}
          <span className="underline">must</span> be a New Mexico resident to
          apply.
        </NoticeBanner>
        <Button
          className="mx-auto"
          type="button"
          onClick={() => setIsModalOpen(false)}
        >
          <FaArrowLeft />
          Back
        </Button>
      </Modal>
    </>
  );
};

export default ApplicantInfo;
