import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import Button, { ColorStyle } from "../../../components/Button/Button";
import FileUpload from "../../../components/FormFields/FileUpload";
import { FaArrowRight } from "react-icons/fa";
import DateField from "../../../components/FormFields/DateField";
import TextField from "../../../components/FormFields/Textfield";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		haveInsuranceOtherThanMedicaid: "",
		hadNMMIPCoverageBefore: "",
		insuranceCompanyDetails: {
			insuranceCompanyName: "",
			coverageEndDate: "",
			whyIsCoverageEnding: "",
		},
		nmmipCoverageDetails: {
			priorCoverageStartDate: "",
			priorCoverageEndDate: "",
			reasonForTermination: "",
		},
	},
	validation: Yup.object().shape({
		haveInsuranceOtherThanMedicaid: Yup.string().required(requiredWarning),
		hadNMMIPCoverageBefore: Yup.string().required(requiredWarning),
		insuranceCompanyDetails: Yup.object()
			.shape({})
			.when(
				"haveInsuranceOtherThanMedicaid",
				([haveInsuranceOtherThanMedicaid], schema) => {
					if (haveInsuranceOtherThanMedicaid === "Yes") {
						return schema.shape({
							insuranceCompanyName: Yup.string().required(requiredWarning),
							coverageEndDate: Yup.string().required(requiredWarning),
							whyIsCoverageEnding: Yup.string().required(requiredWarning),
						});
					} else {
						return schema.shape({});
					}
				}
			),
		nmmipCoverageDetails: Yup.object()
			.shape({})
			.when("hadNMMIPCoverageBefore", ([hadNMMIPCoverageBefore], schema) => {
				if (hadNMMIPCoverageBefore === "Yes") {
					return schema.shape({
						priorCoverageStartDate: Yup.string()
							.nullable()
							.test({
								name: "priorCoverageStartDateRequired",
								message: requiredWarning,
								test(value, ctx) {
									return value ? true : false;
								},
							})
							.test({
								name: "isStartAfterEnd",
								message: "Prior coverage start date cannot be after end date.",
								test(value, ctx) {
									const { priorCoverageEndDate } = ctx.parent;
									let isValid = true;

									// check if start date is after end date
									if (
										value &&
										priorCoverageEndDate &&
										new Date(value) > new Date(priorCoverageEndDate)
									) {
										isValid = false;
									}

									return isValid;
								},
							}),
						priorCoverageEndDate: Yup.string().required(requiredWarning),
						reasonForTermination: Yup.string().required(requiredWarning),
					});
				} else {
					return schema.shape({});
				}
			}),
	}),
};

const QualifyingConditions2 = () => {
	const { values, errors } = useFormikContext<any>();

	return (
		<>
			<Radio
				className="max-w-[475px]"
				name="haveInsuranceOtherThanMedicaid"
				label="I have insurance other than Medicare"
				options={[{ label: "Yes" }, { label: "No" }]}
				required
			/>
			{values["haveInsuranceOtherThanMedicaid"] === "Yes" && (
				<FormGrid className="flex-col">
					<TextField
						className="!flex-none"
						name="insuranceCompanyDetails.insuranceCompanyName"
						label="Name of Insurance Company"
						type="text"
						required
					/>
					<DateField
						className="!flex-none"
						name="insuranceCompanyDetails.coverageEndDate"
						placeholder="Coverage End Date (MM/DD/YYYY)"
						label="Coverage End Date"
						required
					/>
					<TextField
						className="!flex-none"
						name="insuranceCompanyDetails.whyIsCoverageEnding"
						label="Reason for termination"
						type="text"
						required
					/>
				</FormGrid>
			)}
			<Radio
				className="max-w-[475px]"
				name="hadNMMIPCoverageBefore"
				label="I have been covered by NMMIP in the past."
				options={[{ label: "Yes" }, { label: "No" }]}
				required
			/>
			{values["hadNMMIPCoverageBefore"] === "Yes" && (
				<FormGrid className="flex-col">
					<div className="flex !flex-none flex-wrap gap-[1rem] w-full !max-w-[850px]">
						<DateField
							className="h-fit flex-1 max-w-[400px]"
							name="nmmipCoverageDetails.priorCoverageStartDate"
							placeholder="Prior Coverage Start Date (MM/DD/YYYY)"
							label="Prior Coverage Start Date"
							required
						/>
						<DateField
							className="h-fit flex-1 max-w-[400px]"
							name="nmmipCoverageDetails.priorCoverageEndDate"
							placeholder="Prior Coverage End Date (MM/DD/YYYY)"
							label="Prior Coverage End Date"
							required
						/>
					</div>
					<TextField
						className="!flex-none"
						name="nmmipCoverageDetails.reasonForTermination"
						label="Reason for termination"
						type="text"
						required
					/>
				</FormGrid>
			)}
		</>
	);
};

export default QualifyingConditions2;
