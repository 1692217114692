import styled from "styled-components";

const StyledGrid = styled.div`
	/* display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min-content, 400px)); */
	display: flex;
	flex-wrap: wrap;
	gap: 30px;

	& > * {
		flex: 1 1 100px;
		min-width: 200px;
		max-width: 500px;
		height: fit-content;
	}
`;

const FormGrid = (props: FormGridProps) => {
	const { children, className } = props;
	return <StyledGrid className={className}>{children}</StyledGrid>;
};

export default FormGrid;

interface FormGridProps {
	className?: string;
	children: React.ReactNode;
}
