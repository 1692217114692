import { createGlobalStyle, css } from "styled-components";
import { normalize } from "polished";

export const GlobalStyles = createGlobalStyle`
  ${normalize()}
  html {
    box-sizing: border-box;
    font-size: ${({ theme }) => theme.pSize};
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fontCopy};
    background-color: ${({ theme }) => theme.colorBackgroundLightBlue};
    color: ${({ theme }) => theme.colorCopyDarkDark};
  }

  h1, h2, h3, h4, h5, h6 {
      font-weight: ${({ theme }) => theme.fontSemiBold};
      margin: 0;
  }

  p, a, span, b {
    line-height: 30.6px;
  }

  h1 {
    font-size: ${({ theme }) => theme.h1Size};
  }

  h2 {
    font-size: ${({ theme }) => theme.h2Size};
  }

  h3 {
    font-size: ${({ theme }) => theme.h3Size};
  }

  h4 {
    font-size: ${({ theme }) => theme.h4Size};
  }

  h5 {
    font-size: ${({ theme }) => theme.h5Size};
  }

  h6 {
    font-size: ${({ theme }) => theme.h6Size};
  }

  span {
    font-size: ${({ theme }) => theme.spanSize};
  }

  p {
    &, & > span {
      font-size: ${({ theme }) => theme.pSize};
    }
  }

  a {
    font-size: ${({ theme }) => theme.aSize};
    line-height: 23px;
    color: ${({ theme }) => theme.colorLinkLight};
    text-decoration: underline;
    text-underline-offset: 3.5px;
  }

  b, strong {
    font-weight: ${({ theme }) => theme.fontSemiBold};
  }



  ol, ul, menu {
    margin: 0;
    padding: 1rem;
  }

  ul {
    list-style: initial;
  }

  ol {
    list-style: decimal;
  }

 
`;
