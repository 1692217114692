import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css } from "styled-components";
import {
	countyOptions,
	householdSizeOptions,
	incomeThresholdMap,
	povertyGuidelineOptions,
} from "../../LIPP/FormSchema/formInitialValues";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		county: "",
		householdSize: "",
		householdAnnualIncome: "", // only shows upper threshold of annual income
		householdAnnualIncomeString: "", // the string value of lower/upper threshold of annual income
		povertyGuideline: "",
		applicant: {
			firstName: "",
			lastName: "",
			middleInitial: "",
			address: "",
			city: "",
			state: "",
			zipCode: "",
		},
	},
	validation: Yup.object().shape({
		county: Yup.string().required(requiredWarning),
		householdSize: Yup.string().required(requiredWarning),
		householdAnnualIncome: Yup.string().required(requiredWarning),
	}),
};

const Start = () => {
	const { values, setFieldValue } = useFormikContext<any>();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const householdSize = values["householdSize"];
	const incomeThreshold = householdSize
		? incomeThresholdMap(Number(householdSize))
		: null;
	const householdAnnualIncome = values["householdAnnualIncome"];

	const maxIncomeIndex = incomeThreshold?.indexOf(
		Number(householdAnnualIncome)
	);
	const povertyGuideline =
		maxIncomeIndex !== undefined &&
		maxIncomeIndex !== -1 &&
		povertyGuidelineOptions[maxIncomeIndex];

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	useEffect(() => {
		if (
			householdAnnualIncome &&
			incomeThreshold &&
			incomeThreshold.length > 0 &&
			maxIncomeIndex !== undefined &&
			maxIncomeIndex !== -1
		) {
			const lowerThreshold =
				maxIncomeIndex === 0 ? "$0" : `$${incomeThreshold[maxIncomeIndex - 1]}`;

			setFieldValue(
				"householdAnnualIncomeString",
				`${lowerThreshold} - $${Number(householdAnnualIncome)}`
			);
		} else {
			setFieldValue("householdAnnualIncomeString", "");
		}
	}, [householdAnnualIncome]);

	useEffect(() => {
		setFieldValue(
			"povertyGuideline",
			povertyGuideline
				? `You fall between ${povertyGuideline.threshold} of 2023 HHS Poverty Guidelines and qualify for a ${povertyGuideline.reduction}% Premium Reduction`
				: ""
		);
	}, [povertyGuideline]);

	return (
		<StyledLegend className="!max-w-full !mb-0">
			<BlueLegendHeading>Selected household size and income</BlueLegendHeading>
			<div className="flex flex-wrap gap-[1rem]">
				<Dropdown
					className="flex-1"
					name="county"
					label="Select County"
					options={countyOptions}
					required
				/>
				<Dropdown
					className="flex-1 "
					name="householdSize"
					label="Select Household Size"
					options={householdSizeOptions}
					required
				/>

				<Dropdown
					className="flex-1"
					name="householdAnnualIncome"
					label="Select Household Yearly Income"
					options={
						householdSize && incomeThreshold
							? incomeThreshold.map((amount, i) => {
									if (i === 0) {
										return {
											label: `$0 - ${formatter.format(amount)}`,
											value: amount,
										};
									} else {
										const prevThreshold = incomeThreshold[i - 1];
										return {
											label: `${formatter.format(
												prevThreshold + 1
											)} - ${formatter.format(amount)}`,
											value: amount,
										};
									}
							  })
							: []
					}
					required
				/>
			</div>
		</StyledLegend>
	);
};

export default Start;
