import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
	UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

function isDateWithin95Days(dateToCheck: string) {
	// Get today's date
	const today = normalizeDate(new Date());

	// Create a date object representing 95 days BEFORE today
	const pastDate = new Date(today);
	pastDate.setDate(today.getDate() - 95);
	pastDate.setHours(0, 0, 0, 0);

	// Convert dateToCheck to a Date object if it's not already
	const checkDate = normalizeDate(new Date(dateToCheck));

	// Check if dateToCheck is within 95 days
	return checkDate >= pastDate && checkDate <= today;
}

export const schema = {
	values: {
		minimum18MonthsCoverage: "",
		isGroupCoverage: "",
		applyingWithin95Days: "",
		priorCoverageStartDate: "",
		priorCoverageEndDate: "",
	},
	validation: Yup.object().shape({
		minimum18MonthsCoverage: Yup.string().required(requiredWarning),
		isGroupCoverage: Yup.string().required(requiredWarning),
		applyingWithin95Days: Yup.string().required(requiredWarning),
		priorCoverageStartDate: Yup.string()
			.nullable()
			.test({
				name: "priorCoverageStartDate",
				message: requiredWarning,
				test(value, ctx) {
					const { applyingWithin95Days } = ctx.parent;
					return applyingWithin95Days === "Yes" ? (value ? true : false) : true;
				},
			})
			.test({
				name: "isStartAfterEnd",
				message: "Prior coverage start date cannot be after end date.",
				test(value, ctx) {
					const { applyingWithin95Days, priorCoverageEndDate } = ctx.parent;
					let isValid = true;

					if (applyingWithin95Days === "Yes") {
						// check if start date is after end date
						if (
							value &&
							priorCoverageEndDate &&
							new Date(value) > new Date(priorCoverageEndDate)
						) {
							isValid = false;
						}
					}

					return isValid;
				},
			}),
		priorCoverageEndDate: Yup.string()
			.nullable()
			.test({
				name: "priorCoverageEndDate",
				message: requiredWarning,
				test(value, ctx) {
					const { applyingWithin95Days } = ctx.parent;
					return applyingWithin95Days === "Yes" ? (value ? true : false) : true;
				},
			})
			.test({
				name: "isWithin95Days",
				message: "Prior coverage end date must be within 95 days of today",
				test(value, ctx) {
					const { applyingWithin95Days } = ctx.parent;

					return applyingWithin95Days === "Yes"
						? value && isDateWithin95Days(value)
							? true
							: false
						: true;
				},
			}),
	}),
};

const HIPAA = () => {
	const { errors, values } = useFormikContext<any>();
	const minimum18MonthsCoverage = values["minimum18MonthsCoverage"];
	const isGroupCoverage = values["isGroupCoverage"];
	const applyingWithin95Days = values["applyingWithin95Days"];
	const { formId } = useFormContext(); // Get formId from context

	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (minimum18MonthsCoverage === "No") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [minimum18MonthsCoverage]);

	useEffect(() => {
		if (isGroupCoverage === "No") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [isGroupCoverage]);

	useEffect(() => {
		if (applyingWithin95Days === "No") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [applyingWithin95Days]);

	return (
		<>
			<FormGrid className="flex-col">
				<Radio
					className="!max-w-[850px]"
					name="minimum18MonthsCoverage"
					label="I have had a minimum of 18 months of continuous coverage with no single gap of more than 95 days."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-[850px]"
					name="isGroupCoverage"
					label="My last coverage was group coverage through an employer or trade union group health plan (may or may not include COBRA)."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-[850px]"
					name="applyingWithin95Days"
					label="I am applying to NMMIP within 95 days of my prior coverage ending."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				{applyingWithin95Days === "Yes" && (
					<div className="flex !flex-none flex-wrap gap-[1rem] mb-[1rem] w-full !max-w-[850px]">
						<DateField
							className="h-fit flex-1 min-w-[400px]"
							name="priorCoverageStartDate"
							placeholder="Prior Coverage Start Date (MM/DD/YYYY)"
							label="Prior Coverage Start Date"
							required
						/>
						<DateField
							className="h-fit flex-1 min-w-[400px]"
							name="priorCoverageEndDate"
							placeholder="Prior Coverage End Date (MM/DD/YYYY)"
							label="Prior Coverage End Date"
							required
						/>
					</div>
				)}
			</FormGrid>

			<Modal
				modalLabel="HIPAA Eligibility Criteria"
				open={isModalOpen}
				setOpen={setIsModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					To be eligible under HIPAA, you must have answered yes to the previous
					three questions.
				</NoticeBanner>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your response may indicate that you are not eligible for coverage.
					However, you can continue to submit an application for review.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsModalOpen(false)}
				>
					Continue Application <FaArrowRight />
				</Button>
			</Modal>

			<Modal
				modalLabel="Documentation of Prior Coverage"
				buttonLabel="Upload Documentation"
			>
				<NoticeBanner bannerstyle={ColorStyle.Primary}>
					Upload Documentation of Prior Coverage: Proof of coverage from your
					previous insurance carrier(s) such as Individual, Group, COBRA,
					Medicaid, SCI, etc. Ensure documentation shows 18 months of continuous
					coverage.
				</NoticeBanner>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					If you don't have the documentation electronically, please mail it to
					NMMIP upon completing this application.
				</NoticeBanner>
				<FileUpload
					name="documentation.priorCoverage"
					label="Upload Documentation"
					documentationType="priorCoverage"
				/>
			</Modal>

			<div className="max-w-[500px]">
				<UploadedFileList
					formId={formId}
					fieldName={"documentation.priorCoverage"}
					documentationType="priorCoverage"
				/>
			</div>
		</>
	);
};

export default HIPAA;
