import SignatureCanvas from "react-signature-canvas";
import styled, { css, useTheme } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { borders } from "../../theme";
import { FieldType } from "./fieldTypes";
import { Asterisk, Error, StyledFieldWrapper } from "./Field";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StyledLegend from "../Legend/Legend";
import { FaCalendarAlt } from "react-icons/fa";
import { getNestedValue } from "../../utils/getNestedValue";

const DateWrapper = styled.div`
	${(p) =>
		css`
			position: relative;
			.calendar-icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 15px;
			}
			.react-datepicker-wrapper {
				display: flex;

				input {
					width: 100%;
				}
			}
		`}
`;

const DateField = (props: DateFieldProps) => {
	const theme = useTheme();
	const [date, setDate] = useState<Date | null>(null);
	const { name, label, required, showLegend, placeholder, className } = props;
	const { values, setFieldValue } = useFormikContext<any>();
	const [field, meta, helpers] = useField(props);

	const formikVal = getNestedValue(values, name);

	const handleDateChange = (newDate: Date | null) => {
		setDate(newDate);
		setFieldValue(name, newDate);
	};

	useEffect(() => {
		if (formikVal) {
			setDate(formikVal);
		} else setDate(null);
	}, [formikVal]);

	return (
		<StyledFieldWrapper
			className={className}
			haserror={meta.touched && meta.error ? true : false}
		>
			{showLegend && (
				<StyledLegend>
					{label}
					{required && <Asterisk />}
				</StyledLegend>
			)}

			<DateWrapper>
				<DatePicker
					className="datepicker"
					selected={date}
					onChange={(newDate: Date | null) => handleDateChange(newDate)}
					placeholderText={
						placeholder
							? required
								? placeholder + "*"
								: placeholder
							: required
							? "Select a Date" + "*"
							: "Select a Date"
					}
				/>
				<FaCalendarAlt className="calendar-icon" color={theme.colorSecondary} />
			</DateWrapper>

			<Error touched={meta.touched} error={meta.error} />
		</StyledFieldWrapper>
	);
};

export default DateField;

interface DateFieldProps extends FieldType {
	className?: string;
	onSelectDate?(date: Date): void;
	placeholder?: string;
	showLegend?: boolean;
}
