import { FieldType } from "./fieldTypes";
import FormikField, {
	Asterisk,
	Error,
	getPlaceholder,
	StyledFieldWrapper,
} from "./Field";
import { Field, useField, useFormikContext } from "formik";
import { SelectOption } from "./Dropdown";
import { GroupContainer } from "./CheckBox";
import styled, { css } from "styled-components";
import { borders } from "../../theme";
import StyledLegend from "../Legend/Legend";

const RadioLabel = styled.label<{ checked: boolean; haserror?: boolean }>`
	${(p) => css`
		display: flex;
		align-items: center;
		gap: 0.6em;
		max-width: 475px;
		margin-bottom: 0.4em;
		padding: 16px;
		border: 1px solid ${p.theme.colorBackgroundLightLight};
		border-radius: 6px;
		background: ${p.theme.colorField};
		color: ${p.theme.colorCopyBlue};
		font-weight: ${p.theme.fontSemiBold};
		font-size: ${p.theme.spanSize};
		cursor: pointer;
		transition: all 250ms ease;

		&::before {
			content: "";
			border: 5px solid ${p.theme.colorBackgroundBlue};
			outline: 1px solid ${p.theme.colorBackgroundLightLight};
			width: 20px;
			height: 20px;
			border-radius: 20px;
			background: #93b8de;

			${p.checked &&
			css`
				background: ${p.theme.colorBackgroundLightLight};
			`};
		}

		input[type="radio"] {
			display: none;
		}

		&:hover {
			background: ${p.theme.colorFieldSelected};
		}
	`}
`;

const Radio = (props: RadioProps) => {
	const { name, label, options, required, showLegend } = props;
	const [field, meta, helpers] = useField(props);
	const hasvalue =
		field.value !== null && field.value !== undefined && field.value !== "";
	const hasErr = meta.touched && meta.error ? true : false;
	const { values } = useFormikContext<any>();
	const displayLegend = showLegend === undefined ? true : showLegend;

	return (
		<div className={`${props.className ? props.className : ""} flex flex-col`}>
			{displayLegend && (
				<StyledLegend>
					<p>
						{label}
						{required && <Asterisk />}
					</p>
				</StyledLegend>
			)}

			<GroupContainer
				className="flex flex-col !gap-0"
				role="group"
				aria-labelledby={`${name}.radio-group`}
			>
				{options.map((option) => {
					const val = option.value !== undefined ? option.value : option.label;
					const id = `${name}.${val}`;
					return (
						<RadioLabel
							key={id}
							checked={values[name] && values[name].includes(val)}
							haserror={hasErr}
						>
							<Field
								type="radio"
								label={id}
								name={name}
								value={val}
								required={required}
							/>
							{option.label}
						</RadioLabel>
					);
				})}
			</GroupContainer>
			<Error touched={meta.touched} error={meta.error} />
		</div>
	);
};

export default Radio;

interface RadioProps extends FieldType {
	className?: string;
	options: SelectOption[];
	showLegend?: boolean;
}
