import { FormikProvider, useFormik, useFormikContext } from "formik";
import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Button, { ColorStyle } from "../../../components/Button/Button";
import {
	newbornFormValues,
	newbornFormSchema,
	schemas,
} from "./formInitialValues";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { steps, renderFormStep } from "./Steps";
import { borders } from "../../../theme";
import { objectToArray } from "../../../utils/objectToArray";
import { FaExclamationTriangle } from "react-icons/fa";
import BrokerPopup from "../../../components/BrokerPopup/BrokerPopup";
import ProgressIndicator from "../../../components/ProgressIndictaor/ProgressIndicator";
import { PaymentTypes } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import Modal from "../../../components/Modal/Modal";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import { useFormContext } from "../../../components/FormContext/FormContext";
import axios from "axios";
import PdfDownload from "../../../components/PdfDownload/PdfDownload";
import NewbornPdf from "../Pdf/NewbornPdf";
import { createPortal } from "react-dom";
import { PDFViewer } from "@react-pdf/renderer";
import { pdfStyles } from "../../../components/PdfDownload/PdfTemplate";

export const FormSeparator = styled.hr`
	height: 5px;
	width: 100%;
	background: linear-gradient(90deg, #df7285, #e68d9d);
	/* margin: 0 0 30px 0; */
	border-radius: 5px;
`;

export const ErrorSummary = styled.div`
	${(p) => css`
		display: flex;
		flex-direction: column;
		background: #ffeff2;
		width: 100%;
		padding: 24px;
		gap: 10px;
		border-radius: ${borders.fields.radius};
		border: solid 2px ${p.theme.colorSecondary};
		color: ${p.theme.colorSecondary};

		h2 {
			color: ${p.theme.colorSecondary} !important;
		}
	`};
`;

const NewbornForm = () => {
	const [activeStep, setActiveStep] = useState(0);
	const isFirstStep = activeStep === 0;
	const isLastStep = activeStep === steps.length - 2;
	const isSubmissionPage = activeStep === steps.length - 1;
	const [showErrors, setShowErrors] = useState(false);
	const scrollRef = useRef<any>(null);
	const [isMoneyOrderModalOpen, setIsMoneyOrderModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const { formId } = useFormContext(); // Get formId from context
	const downloadLinkContainer = document.querySelector(
		"#download-link-container"
	);
	const [initialFormValues, setInitialFormValues] = useState(newbornFormValues);

	// Fetch existing form data and merge it with initial values
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/api/forms/NewbornForm/${formId}`);
				const fetchedData = response.data;

				const mergedValues = {
					...newbornFormValues,
					...fetchedData,
				};

				formikProps.resetForm({ values: mergedValues });
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch Newborn form data", error);
				setLoading(false);
			}
		};

		if (formId) {
			fetchData();
		} else {
			setLoading(false);
		}
	}, [formId]);

	// Function to flatten data (reuse if needed)
	const flattenFetchedData = (fetchedData: any) => {
		let flattenedData: any = {};
		Object.keys(fetchedData).forEach((schemaKey) => {
			const schemaData = fetchedData[schemaKey];
			if (typeof schemaData === "object" && !Array.isArray(schemaData)) {
				Object.keys(schemaData).forEach((innerKey) => {
					if (typeof schemaData[innerKey] === "object") {
						flattenedData = { ...flattenedData, ...schemaData };
					} else {
						flattenedData[innerKey] = schemaData[innerKey];
					}
				});
			} else {
				flattenedData = { ...flattenedData, ...schemaData };
			}
		});
		return flattenedData;
	};

	// Handle saving form sections
	const handleSaveForm = async (values: any) => {
		const formSection = getFormSectionByStep(activeStep, values);
		console.log("Form Section being saved: ", formSection);
		try {
			await axios.post(`/api/forms/update/NewbornForm/${formId}`, formSection); // Use formId from context
			console.log("Form saved successfully");
		} catch (error) {
			console.error("Failed to save form data:", error);
		}
	};

	const getFormSectionByStep = (step: number, values: any) => {
		const stepName = Object.keys(schemas)[step - 1];
		const schema = schemas[stepName as keyof typeof schemas];

		if (schema && schema.values) {
			const sectionValues: Record<string, any> = {};
			for (const key in schema.values) {
				if (Object.prototype.hasOwnProperty.call(schema.values, key)) {
					sectionValues[key] = values[key];
				}
			}

			return { [stepName]: sectionValues };
		}

		return {};
	};

	const formikProps = useFormik({
		initialValues: newbornFormValues,
		validationSchema: newbornFormSchema[activeStep],
		validateOnChange: true,
		onSubmit: async (values, actions) => {
			await handleSaveForm(values); // Save form data before submitting

			if (isLastStep) {
				console.log("entire form payload", values);
				setActiveStep(activeStep + 1);
			} else {
				if (
					activeStep === 3 &&
					formikProps.values.paymentType === PaymentTypes[0].label
				) {
					setIsMoneyOrderModalOpen(true);
				}

				setActiveStep(activeStep + 1);
				actions.setTouched({});
				actions.setSubmitting(false);
			}

			window.scrollTo({ top: 0, behavior: "smooth" });
		},
	});

	const isNewbornResidentOfNM = formikProps.values.isNewbornResidentOfNM
		? formikProps.values.isNewbornResidentOfNM
		: null;

	const handlePrev = async () => {
		await formikProps.submitForm(); // Save the form before navigating back
		setActiveStep(activeStep - 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		console.log("formikProps.values", formikProps.values);
	}, [formikProps.values]);

	useEffect(() => {
		console.log("formikProps.errors", formikProps.errors);
	}, [formikProps.errors]);

	const errorsArr =
		Object.entries(formikProps.errors).length > 0 &&
		objectToArray(formikProps.errors);

	const handleShowErrors = () => {
		setShowErrors(true);
	};

	useEffect(() => {
		setShowErrors(false);
	}, [activeStep]);

	useEffect(() => {
		if (showErrors && scrollRef.current) {
			const elementPosition =
				scrollRef.current.getBoundingClientRect().top + window.scrollY;
			const offset = 100;

			// Scroll to the position with the offset
			window.scrollTo({
				top: elementPosition - offset,
				behavior: "smooth",
			});
		}
	}, [showErrors]);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault();
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	return (
		<>
			{downloadLinkContainer &&
				activeStep !== 0 &&
				createPortal(
					<PdfDownload
						pdf={<NewbornPdf formValues={formikProps.values} />}
						fileName="newborn_application.pdf"
					/>,
					downloadLinkContainer
				)}

			{errorsArr && showErrors && (
				<ErrorSummary ref={scrollRef}>
					<h2 className="flex items-center gap-[1rem]">
						<FaExclamationTriangle />
						There was a problem with your submission. Please review the required
						fields below.
					</h2>

					{errorsArr.map((obj, index) => {
						const [[key, value]] = Object.entries(obj);
						return (
							<p>
								{key}: {value}
							</p>
						);
					})}
				</ErrorSummary>
			)}
			<FormikProvider value={formikProps}>
				<div className="flex justify-between items-center gap-[1rem] flex-wrap md:flex-nowrap">
					<h1 className="whitespace-pre-wrap">{steps[activeStep].heading}</h1>
					{!isSubmissionPage && (
						<ProgressIndicator
							activeStep={activeStep}
							totalSteps={steps.length - 2}
						/>
					)}
				</div>

				<FormSeparator />
				{steps[activeStep].subHeading && <p>{steps[activeStep].subHeading}</p>}

				{renderFormStep(activeStep)}
				{!isSubmissionPage && (
					<div className="flex gap-[1rem] mt-[32px]">
						{!isFirstStep && (
							<Button type="button" onClick={handlePrev}>
								<FaArrowLeft />
								Back
							</Button>
						)}

						<Button
							className="font-semibold"
							type="submit"
							disabled={
								formikProps.isSubmitting ||
								(isNewbornResidentOfNM && activeStep === 1
									? isNewbornResidentOfNM === "No"
									: false)
							}
							onClick={() => {
								handleShowErrors();
								formikProps.submitForm();
							}}
							buttonstyle={
								isLastStep ? ColorStyle.Secondary : ColorStyle.Primary
							}
						>
							{isFirstStep
								? "Start Application"
								: isLastStep
								? "Submit Application"
								: "Next"}
							<FaArrowRight />
						</Button>
					</div>
				)}
			</FormikProvider>
			<BrokerPopup isOpen={!isSubmissionPage} />

			<Modal
				modalLabel="Money Order or Check Premium Payment"
				open={isMoneyOrderModalOpen}
				setOpen={setIsMoneyOrderModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your application will be marked as PENDING until your money order or
					check payment of first premium is received.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsMoneyOrderModalOpen(false)}
				>
					Continue Application
					<FaArrowRight />
				</Button>
			</Modal>
		</>
	);
};

export default NewbornForm;
