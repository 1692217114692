import { Field, useField, useFormikContext } from "formik";
import { SelectOption } from "./Dropdown";
import FormikField, { Error, FormikFieldProps } from "./Field";
import FormikFieldWrapper from "./FieldWrapper";
import { FieldType } from "./fieldTypes";
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { borders } from "../../theme";

export const GroupContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
`;

export const CheckBoxLabel = styled.label<{ checked?: boolean }>`
	${(p) => css`
		position: relative;
		display: flex;
		align-items: center;
		flex-basis: 375px;
		gap: 25px;
		background: ${p.theme.colorField};
		padding: 24px;
		border-radius: ${borders.fields.radius};
		border: solid 1px ${p.theme.colorBackgroundLightLight};
		font-weight: ${p.theme.fontSemiBold};
		color: ${p.theme.colorCopyBlue};
		transition: all 0.25s ease;
		cursor: pointer;
		transition: all 250ms ease;

		&:hover {
			background: ${p.theme.colorFieldSelected};
		}

		input[type="checkbox"] {
			position: relative;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			outline: 0;
			width: 30px;
			height: 30px;
			border-radius: 6px;
			border: 1px solid ${p.theme.colorBackgroundLightLight};
			color: ${p.theme.colorCopyLightLight};
			background: ${p.checked
				? p.theme.colorCopyBlue
				: p.theme.colorBackgroundBlue};
			cursor: pointer;

			${p.checked &&
			css`
				text-align: center;

				&::after {
					content: "\\2714";
					position: relative;
					display: block;
					top: 50%;
					transform: translateY(-50%);
					color: ${p.theme.colorCopyLightLight};
					font-weight: ${p.theme.fontDefaultWeight};
				}
			`};
		}
	`};
`;

const CheckBox = (props: CheckBoxProps) => {
	const { name, label, options, required } = props;
	const [field, meta, helpers] = useField(props);
	const hasvalue =
		field.value !== null && field.value !== undefined && field.value !== "";
	const hasErr = meta.touched && meta.error ? true : false;
	const { values } = useFormikContext<any>();

	return (
		<div className="flex flex-col">
			<GroupContainer role="group" aria-labelledby="checkbox-group">
				{options.map((option) => {
					const val = option.value !== undefined ? option.value : option.label;
					const id = `${name}.${val}`;
					return (
						<CheckBoxLabel
							key={id}
							htmlFor={id}
							checked={values[name] && values[name].includes(val)}
						>
							<Field
								id={id}
								type="checkbox"
								label={id}
								name={name}
								value={val}
								required={required}
							/>
							{option.label}
						</CheckBoxLabel>
					);
				})}
			</GroupContainer>
			<Error touched={meta.touched} error={meta.error} />
		</div>
	);
};

export default CheckBox;

interface CheckBoxProps extends FieldType {
	options: SelectOption[];
}

interface CheckBoxFormikField extends FormikFieldProps {
	checked: boolean;
}
