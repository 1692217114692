import React, { useEffect } from "react";

declare global {
	interface Window {
		googleTranslateElementInit: () => void;
		google: any;
	}
}

const GoogleTranslateWidget: React.FC = () => {
	useEffect(() => {
		// Prevent the script from being added multiple times
		if (!document.querySelector("#google-translate-script")) {
			// Add the Google Translate script to the page
			const script = document.createElement("script");
			script.id = "google-translate-script"; // Unique ID to ensure it's only added once
			script.type = "text/javascript";
			script.src =
				"//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
			script.async = true;
			document.body.appendChild(script);
		}

		// Initialize the Google Translate element and handle language persistence
		window.googleTranslateElementInit = () => {
			if (window.google && window.google.translate) {
				if (!document.querySelector(".goog-te-combo")) {
					new window.google.translate.TranslateElement(
						{
							pageLanguage: "en", // Default language
							includedLanguages: "en,es", // Only include English and Spanish
							layout:
								window.google.translate.TranslateElement.InlineLayout.SIMPLE,
							autoDisplay: false, // Avoid auto-display of any additional UI
						},
						"google_translate_element"
					);
				}

				// Check if a language preference is stored in localStorage
				const selectedLanguage = localStorage.getItem("selectedLanguage");
				if (selectedLanguage) {
					const selectElement =
						document.querySelector<HTMLSelectElement>(".goog-te-combo");
					if (selectElement) {
						selectElement.value = selectedLanguage; // Set the dropdown to the stored language
						selectElement.dispatchEvent(new Event("change")); // Trigger the change event to apply the translation
					}
				}
			}
		};

		// Store the selected language when the user changes it
		const handleLanguageChange = () => {
			const selectElement =
				document.querySelector<HTMLSelectElement>(".goog-te-combo");
			if (selectElement) {
				selectElement.addEventListener("change", () => {
					localStorage.setItem("selectedLanguage", selectElement.value); // Store the selected language
				});
			}
		};

		handleLanguageChange(); // Call the function to store language on change
	}, []);

	return (
		<div>
			{/* Google Translate element */}
			<div id="google_translate_element"></div>

			{/* CSS to style the elements and use ruby layout for text, pipe, and dropdown */}
			<style>{`
        #google_translate_element {
          position: fixed;
          top: 20px;
          right: 20px;
          z-index: 1000; /* Ensure it floats above other content */
          background-color: white;
          border: 1px solid #d5d5d5;
          border-radius: 5px;
          padding: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          display: inline-flex; /* Keep everything in one line */
          align-items: center; /* Center align elements vertically */
        }

        #google_translate_element span {
          display: ruby;
        }

        .goog-te-gadget-simple {
          display: inline-flex !important;
          align-items: center !important;
          background-color: transparent !important;
          border: none !important;
          padding: 0 !important;
        }
      `}</style>
		</div>
	);
};

export default GoogleTranslateWidget;
