import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import StyledList from "../../../components/Lists/Lists";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		affirmation: {
			NMMIPenrolleeSignature: "",
			relationshipToNewborn: "",
			todaysDate: "",
		},
	},
	validation: Yup.object().shape({
		affirmation: Yup.object().shape({
			NMMIPenrolleeSignature: Yup.string().required(requiredWarning),
			relationshipToNewborn: Yup.string().required(requiredWarning),
			todaysDate: Yup.string()
				.required(requiredWarning)
				.test({
					name: "today",
					message: "Must select today's date",
					test(value, ctx) {
						if (value) {
							return new Date(value).getDate() === new Date().getDate();
						} else return true;
					},
				}),
		}),
	}),
};

const Affirmation = () => {
	const { values } = useFormikContext<any>();

	return (
		<>
			<h2>
				I understand the first month's premium must be included with the
				application.
			</h2>

			<p>
				I certify that the foregoing statements are true and accurate. I
				understand that no coverage will be effective until the full initial
				premium is paid and this application has been approved by the NMMIP
				Administrator. I understand that this policy will automatically be
				canceled 31 days after the newborn's birth unless there is a qualifying
				event which makes the newborn eligible for NMMIP. I further understand
				that this coverage is a benefit of my policy with NMMIP, and I certify
				that I am the biological, adoptive parent, or legal guardian of this
				child.
			</p>

			<Signature
				name="affirmation.NMMIPenrolleeSignature"
				label="Signature of NMMIP Enrollee"
				signatureType="NMMIPEnrolleeSignature"
				required
			/>

			<FormGrid>
				<TextField
					type="text"
					name="affirmation.relationshipToNewborn"
					label="Relationship to Newborn"
					autoComplete="off"
					required
				/>
				<DateField
					className="max-w-[300px]"
					name="affirmation.todaysDate"
					label="Today's Date"
					placeholder="Select Today's Date"
					required
				/>
			</FormGrid>

			{values["paymentType"] === "Money Order or Check" && (
				<>
					<p className="leading-[1.5rem]">
						<b>
							If paying premium by check or money order, you must MAIL the
							application and all attachments WITH the payment.
						</b>
						<br />
						Please include the applicant's first and last name, along with the
						email address or phone number used to sign into this online
						application.
					</p>

					<p className="leading-[1.5rem]">
						<b>Make money order or check payable to:</b>
						<br />
						New Mexico Medical Insurance Pool (NMMIP)
					</p>

					<p className="leading-[1.5rem]">
						<b>Mail complete application and premium check to:</b>
						<br />
						New Mexico Medical Insurance Pool (NMMIP) <br />
						P.O. Box 27745 <br />
						Albuquerque, NM 87125-7745
					</p>
				</>
			)}
		</>
	);
};

export default Affirmation;
