import React, { JSXElementConstructor, ReactElement } from "react";
import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
} from "@react-pdf/renderer";
import Button, { ColorStyle } from "../Button/Button";
import { FaDownload } from "react-icons/fa";
import styled from "styled-components";

const StyledDownloadLink = styled(PDFDownloadLink)`
	text-decoration: none;
`;

const PdfDownload = (props: PdfDownloadProps) => {
	const { pdf, fileName } = props;
	return (
		<div>
			<StyledDownloadLink document={pdf} fileName={fileName}>
				{/* @ts-ignore  */}
				{({ blob, url, loading, error }) => (
					<Button
						type="button"
						buttonstyle={ColorStyle.Primary}
						disabled={loading}
					>
						Download Application
						<FaDownload />
					</Button>
				)}
			</StyledDownloadLink>
		</div>
	);
};

export default PdfDownload;

interface PdfDownloadProps {
	pdf: ReactElement<DocumentProps, string | JSXElementConstructor<any>>;
	fileName: string;
}
