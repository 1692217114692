import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import {
	DeductibleAmounts,
	MonthsOfYear,
	USStates,
} from "../FormSchema/formInitialValues";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css } from "styled-components";
import {
	countyOptions,
	householdSizeOptions,
	incomeThresholdMap,
	povertyGuidelineOptions,
} from "../../LIPP/FormSchema/formInitialValues";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		applyingForLipp: "",
		coverageStartMonth: "",
		county: "",
		householdSize: "",
		householdAnnualIncome: "", // only shows upper threshold of annual income
		householdAnnualIncomeString: "", // the string value of lower/upper threshold of annual income
		deductible: "",
	},
	validation: Yup.object().shape({
		applyingForLipp: Yup.string().required(requiredWarning),
		coverageStartMonth: Yup.string().required(requiredWarning),
		county: Yup.string().test({
			name: "countyRequired",
			message: requiredWarning,
			test(value, ctx) {
				const { applyingForLipp } = ctx.parent;
				return applyingForLipp === "Yes" ? (value ? true : false) : true;
			},
		}),
		householdSize: Yup.string().test({
			name: "householdSizeRequired",
			message: requiredWarning,
			test(value, ctx) {
				const { applyingForLipp } = ctx.parent;
				return applyingForLipp === "Yes" ? (value ? true : false) : true;
			},
		}),
		householdAnnualIncome: Yup.string().test({
			name: "householdAnnualIncomeRequired",
			message: requiredWarning,
			test(value, ctx) {
				const { applyingForLipp } = ctx.parent;
				return applyingForLipp === "Yes" ? (value ? true : false) : true;
			},
		}),
		deductible: Yup.string().required(requiredWarning),
	}),
};

const CoverageDetails = () => {
	const { values, setFieldValue } = useFormikContext<any>();
	const coverageStartMonth = values["coverageStartMonth"];
	const applyingForLipp = values["applyingForLipp"];
	const [isModalOpen, setIsModalOpen] = useState(false);

	const householdSize = values["householdSize"];
	const incomeThreshold = householdSize
		? incomeThresholdMap(Number(householdSize))
		: null;
	const householdAnnualIncome = values["householdAnnualIncome"];

	const maxIncomeIndex = incomeThreshold?.indexOf(
		Number(householdAnnualIncome)
	);
	const povertyGuideline =
		maxIncomeIndex !== undefined &&
		maxIncomeIndex !== -1 &&
		povertyGuidelineOptions[maxIncomeIndex];

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	useEffect(() => {
		if (coverageStartMonth) {
			const currentMonth = new Date().getMonth();
			const selectedMonth = new Date(
				`${coverageStartMonth} 1, 2000`
			).getMonth();
			const nextMonth = (currentMonth + 1) % 12;
			const nextTwoMonths = ((currentMonth + 1) % 12) + 1;

			// show modal if selected month is NOT next month NOR next 2 months
			if (selectedMonth !== nextMonth && selectedMonth !== nextTwoMonths) {
				setIsModalOpen(true);
			}
		} else {
			setIsModalOpen(false);
		}
	}, [coverageStartMonth]);

	useEffect(() => {
		if (
			householdAnnualIncome &&
			incomeThreshold &&
			incomeThreshold.length > 0 &&
			maxIncomeIndex !== undefined &&
			maxIncomeIndex !== -1
		) {
			const lowerThreshold =
				maxIncomeIndex === 0 ? "$0" : `$${incomeThreshold[maxIncomeIndex - 1]}`;

			setFieldValue(
				"householdAnnualIncomeString",
				`${lowerThreshold} - $${Number(householdAnnualIncome)}`
			);
		} else {
			setFieldValue("householdAnnualIncomeString", "");
		}
	}, [householdAnnualIncome]);

	return (
		<>
			<p>
				Coverage is effective{" "}
				<span className="underline">on the first day of the month</span>{" "}
				following receipt of completed application and first premium payment.
				<br /> <br />
				To determine rates and the amount you would pay for insurance through
				NMMIP, view the benefits and premium rates information. There, you can
				find detailed information on monthly premiums, qualifying rates, and
				discounts available through the Low Income Premium Program (LIPP). These
				resources will help you understand the cost structure and select the
				appropriate plan based on your eligibility and financial situation.
			</p>

			<div className="flex flex-wrap gap-[1rem]">
				<Button
					type="button"
					href={"https://nmmip.org/members/summary-of-benefits/"}
				>
					View Benefits Information
				</Button>
				<Button
					type="button"
					href={"https://nmmip.org/eligibility-and-coverage/2024-rate-tables/"}
				>
					View Premium Rates Information
				</Button>
				<Button
					type="button"
					href={
						"https://nmmip.org/eligibility-and-coverage/low-income-premium-program-lipp/"
					}
				>
					View LIPP Qualification Guidelines
				</Button>
				<Button
					type="button"
					href={"https://nmmip.org/eligibility-and-coverage/2024-rate-tables/"}
				>
					View LIPP Rate Tables
				</Button>
			</div>
			<FormGrid className="flex-col">
				<Radio
					className="!max-w-[700px]"
					name="applyingForLipp"
					label="Are you applying for the Low Income Premium Program (LIPP)?"
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>

				{applyingForLipp === "Yes" && (
					<BlueLegendHeading className="!max-w-full !flex-none !mt-0 !mb-0">
						You've indicated that you'll be applying for LIPP. Once you complete
						this application for coverage, you'll be directed to the LIPP
						application to provide further information including income details.
					</BlueLegendHeading>
				)}

				<StyledLegend className="!max-w-[700px] !mb-0">
					<BlueLegendHeading>
						Select what month would you like your NMMIP coverage to begin:
					</BlueLegendHeading>
					<Dropdown
						className="!max-w-[350px]"
						name="coverageStartMonth"
						label="Select a Month"
						options={MonthsOfYear}
						required
					/>
				</StyledLegend>
				{applyingForLipp === "Yes" && (
					<StyledLegend className="max-w-full !mb-0">
						<BlueLegendHeading>
							Select household size and income
						</BlueLegendHeading>
						<div className="flex flex-wrap gap-[1rem]">
							<Dropdown
								className="flex-1"
								name="county"
								label="Select County"
								options={countyOptions}
								required
							/>
							<Dropdown
								className="flex-1 "
								name="householdSize"
								label="Select Household Size"
								options={householdSizeOptions}
								required
							/>

							<Dropdown
								className="flex-1"
								name="householdAnnualIncome"
								label="Select Household Yearly Income"
								options={
									householdSize && incomeThreshold
										? incomeThreshold.map((amount, i) => {
												if (i === 0) {
													return {
														label: `$0 - ${formatter.format(amount)}`,
														value: amount,
													};
												} else {
													const prevThreshold = incomeThreshold[i - 1];
													return {
														label: `${formatter.format(
															prevThreshold + 1
														)} - ${formatter.format(amount)}`,
														value: amount,
													};
												}
										  })
										: []
								}
								required
							/>
						</div>
					</StyledLegend>
				)}

				<StyledLegend
					className={`!mb-0 ${applyingForLipp === "Yes" ? "!max-w-full " : ""}`}
				>
					<BlueLegendHeading>
						Select a deductible amount for your coverage:
					</BlueLegendHeading>

					{applyingForLipp === "Yes" && povertyGuideline ? (
						<p className="mb-[1rem] !font-normal">
							You fall between {povertyGuideline.threshold} of 2023 HHS Poverty
							Guidelines and qualify for a {povertyGuideline.reduction}% Premium
							Reduction
						</p>
					) : (
						<></>
					)}
					<Dropdown
						className="!max-w-[700px]"
						name="deductible"
						label="Select an Amount"
						options={DeductibleAmounts}
						required
					/>
				</StyledLegend>
			</FormGrid>

			<Modal
				modalLabel="Pool Eligibility Criteria"
				open={isModalOpen}
				setOpen={setIsModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Primary}>
					You can only select the next month or the month thereafter for NMMIP
					coverage start date.
				</NoticeBanner>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your response may indicate that you are not eligible for coverage.
					However, you can continue to submit an application for review.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsModalOpen(false)}
				>
					Continue Application <FaArrowRight />
				</Button>
			</Modal>
		</>
	);
};

export default CoverageDetails;
