import Intro from "../SectionIntro/Intro";
import ApplicantInfo from "../Section1/ApplicantInfo";
import PremiumPayment from "../Section2/PremiumPayment";
import Affirmation from "../Section3/Affirmation";
import Submission from "../Submission/Submission";
import Deductible from "../Section2/Deductible";

export const renderFormStep = (step: number) => {
	switch (step) {
		case 0:
			return <Intro />;
		case 1:
			return <ApplicantInfo />;
		case 2:
			return <Deductible />;
		case 3:
			return <PremiumPayment />;
		case 4:
			return <Affirmation />;
		case 5:
			return <Submission />;
		default:
			return <div>Not Found</div>;
	}
};

export const steps: Step[] = [
	{
		heading: "Application for Newborn Coverage",
	},
	{
		heading: "Section 1: Applicant Information",
		subHeading: "Fill in your information below.",
	},
	{
		heading: "Section 2: Premium Payment Information",
	},
	{
		heading: "Section 2: Premium Payment Information",
	},
	{
		heading: "Section 3: Affirmation, Understanding & Disclosure Authorization",
	},
	{
		heading: "Thank You For Submitting Your Application for Newborn Coverage",
	},
];

interface Step {
	heading: string;
	subHeading?: string;
}
