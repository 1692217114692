import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import Button from "../../../components/Button/Button";

export const schema = {
	values: {},
	validation: Yup.object().shape({}),
};

const Intro = () => {
	return (
		<>
			<p>
				To be eligible for the Medicare Carve-Out plan, you must be under age 65
				and be enrolled in Medicare Parts A{" "}
				<span className="underline">and</span> B due to disability.
				<br /> <br />
				Every person applying for a New Mexico Medical Insurance Pool policy,
				even if in the same family, must complete a separate application.
			</p>
			<Button
				type="button"
				href={
					"https://nmmip.org/wp-content/uploads/2023/12/2024-Medicare-Carveout-plan-rates.pdf"
				}
			>
				Medicare Carve Out Rate Plans
			</Button>

			<p>
				If you have questions or need assistance completing this application,
				please contact 1-866-306-1882 or email{" "}
				<a href="mailto:NMMIP_Eligibility@90degreebenefits.com">
					NMMIP_Eligibility@90degreebenefits.com.
				</a>
			</p>
		</>
	);
};

export default Intro;
