import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Link,
	Image,
} from "@react-pdf/renderer";
import {
	healthCareFormValues,
	MedicalConditionOptions,
} from "../FormSchema/formInitialValues";
import PdfPage, {
	displayFormattedDate,
	FieldHeader,
	fieldStyles,
	ListItem,
	PdfCheckbox,
	PdfField,
	pdfStyles,
	YesNoCheckbox,
} from "../../../components/PdfDownload/PdfTemplate";
import calculateAge from "../../../utils/calculateAge";

export const healthCareCoveragePdfStyles = StyleSheet.create({});

const HealthCareCoveragePdf = (props: {
	formValues: typeof healthCareFormValues;
}) => {
	const { formValues } = props;
	const revisionLabel = "Revision 07.15.24";

	return (
		<Document>
			<PdfPage
				heading="Application for Coverage"
				pageLabel="Page 1 of 5"
				revisionLabel={revisionLabel}
			>
				<View style={{ ...pdfStyles.section }}>
					<Text>
						To apply for New Mexico Medical Insurance Pool (NMMIP) coverage,{" "}
						<Text style={pdfStyles.fontBold}>
							complete all boxes and sections
						</Text>{" "}
						of this
					</Text>
					<Text style={{ ...pdfStyles.mb10 }}>
						Application for Coverage and the Application Checklist{" "}
						<Text style={pdfStyles.fontBold}>in ink</Text>. Submit the
						checklist, application, supporting documents, and first premium
						payment by mail, email, or fax.
					</Text>

					<Text>
						Detailed information about benefits and premium rates is available
						online at{" "}
						<Link style={pdfStyles.link} src="https://www.nmmip.org">
							www.nmmip.org
						</Link>
						.
					</Text>

					<Text>
						If you have questions or need assistance completing this
						application, please contact{" "}
						<Link style={pdfStyles.link} src="tel:+18663061882">
							1-866-306-1882
						</Link>
					</Text>
					<Text>
						or email{" "}
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
						.
					</Text>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 1: Applicant Information
					</Text>

					<Text style={{ ...pdfStyles.mb10 }}>
						If multiple family members are applying for coverage, each member
						must complete a separate Application for Coverage and Application
						Checklist. If you are applying for the Low Income Premium Program
						(LIPP), complete the LIPP Application and submit it with this
						Application for Coverage.
					</Text>

					{/* Applicant info */}
					<PdfField label="Last Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.lastName}</Text>
					</PdfField>
					<PdfField label="First Name" style={{ ...pdfStyles.w40 }}>
						<Text>{formValues.applicant.firstName}</Text>
					</PdfField>
					<PdfField
						label="MI"
						style={{ ...pdfStyles.w10, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.middleInitial}</Text>
					</PdfField>
					<PdfField label="Age" style={{ ...pdfStyles.w10 }}>
						<Text>
							{formValues.applicant.birthDate &&
								calculateAge(formValues.applicant.birthDate)}
						</Text>
					</PdfField>
					<PdfField
						label="Birth Date (MM/DD/YYYY)"
						style={{ ...pdfStyles.w40 }}
					>
						{displayFormattedDate(formValues.applicant.birthDate)}
					</PdfField>
					<PdfField label="Gender" style={{ ...pdfStyles.w10 }}>
						<Text>{formValues.applicant.gender}</Text>
					</PdfField>
					<PdfField
						label="Social Security Number (if applicable)"
						style={{ ...pdfStyles.w40, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.ssn}</Text>
					</PdfField>

					{/* residence address */}
					<PdfField
						label="Residence Address (Physical address required)"
						style={{ ...pdfStyles.w35 }}
					>
						<Text>{formValues.residence.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.residence.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField label="Zip" style={{ ...pdfStyles.w15 }}>
						<Text>{formValues.residence.zipCode}</Text>
					</PdfField>
					<PdfField
						label="County"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.residence.county}</Text>
					</PdfField>

					{/* mailing address */}
					<PdfField label="Mailing Address" style={{ ...pdfStyles.w35 }}>
						<Text>{formValues.mailing.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.mailing.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField label="Zip" style={{ ...pdfStyles.w15 }}>
						<Text>{formValues.mailing.zipCode}</Text>
					</PdfField>
					<PdfField
						label="County"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.mailing.county}</Text>
					</PdfField>

					{/* phone */}
					<PdfField
						label="Home Phone (include area code)"
						style={{ ...pdfStyles.wThird }}
					>
						<Text>{formValues.applicant.homePhone}</Text>
					</PdfField>
					<PdfField
						label="Work Phone (include area code)"
						style={{ ...pdfStyles.wThird }}
					>
						<Text>{formValues.applicant.workPhone}</Text>
					</PdfField>
					<PdfField
						label="Cell Phone (include area code)"
						style={{ ...pdfStyles.wThird, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.cellPhone}</Text>
					</PdfField>

					{/* contact info */}
					<PdfField label="Email Address" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.email}</Text>
					</PdfField>
					<PdfField
						label="Preferred Language"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.preferredLanguage}</Text>
					</PdfField>
					<PdfField
						label="Preferred Method of Communication"
						style={{ ...pdfStyles.w50 }}
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Phone"
								isChecked={
									formValues.applicant.preferredCommunication === "Phone"
								}
							/>
							<PdfCheckbox
								label="Text"
								isChecked={
									formValues.applicant.preferredCommunication === "Text"
								}
							/>
							<PdfCheckbox
								label="Email"
								isChecked={
									formValues.applicant.preferredCommunication === "Email"
								}
							/>
						</View>
					</PdfField>
					<PdfField
						label="Hearing Impaired"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderRight }}
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Yes"
								isChecked={formValues.isHearingImpaired === "Yes"}
							/>
							<PdfCheckbox
								label="No"
								isChecked={formValues.isHearingImpaired === "No"}
							/>
						</View>
					</PdfField>

					<PdfField
						label="Preferred Additional Contact Person"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
						}}
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Emergency Contact"
								isChecked={
									formValues.applicant.preferredAdditionalContact ===
									"Emergency Contact"
								}
							/>
							<PdfCheckbox
								label="Power of Attorney"
								isChecked={
									formValues.applicant.preferredAdditionalContact ===
									"Power of Attorney"
								}
							/>
						</View>
					</PdfField>

					<PdfField
						label="Individual or Agency Name "
						style={{ ...pdfStyles.wThird }}
					>
						<Text>
							{formValues.applicant.preferredAdditionalContact ===
							"Emergency Contact"
								? formValues.applicant.emergencyContactName
								: formValues.applicant.poaAgencyName}
						</Text>
					</PdfField>
					<PdfField label="Address" style={{ ...pdfStyles.wThird }}>
						<Text>
							{formValues.applicant.preferredAdditionalContact ===
							"Emergency Contact"
								? formValues.applicant.emergencyContactAddress
								: formValues.applicant.poaAddress}
						</Text>
					</PdfField>
					<PdfField
						label="Phone Number"
						style={{ ...pdfStyles.wThird, ...fieldStyles.borderRight }}
					>
						<Text>
							{formValues.applicant.preferredAdditionalContact ===
							"Emergency Contact"
								? formValues.applicant.emergencyContactPhone
								: formValues.applicant.poaPhone}
						</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text
							style={{
								...pdfStyles.flexRow,
								...pdfStyles.font8,
							}}
						>
							Are you a resident of the State of New Mexico? If YES,{" "}
							<Text style={pdfStyles.fontBold}>provide proof of residency</Text>{" "}
							(lease agreement, mortgage statement, utility bill, or other
							document).
						</Text>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Yes"
								isChecked={formValues.residence.state === "New Mexico"}
							/>
							<PdfCheckbox
								label="No"
								isChecked={
									formValues.residence.state !== "" &&
									formValues.residence.state !== "New Mexico"
								}
							/>
						</View>
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading="Application for Coverage"
				pageLabel="Page 2 of 5"
				revisionLabel={revisionLabel}
			>
				{/* section 2 */}
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 2: Coverage Start Date & Deductible
					</Text>

					<PdfField
						label=""
						style={{ ...pdfStyles.wFull, ...fieldStyles.borderRight }}
					>
						<Text>
							Coverage is effective{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								on the first day of the month following receipt
							</Text>{" "}
							of completed application and first premium payment.
						</Text>
					</PdfField>
					<PdfField
						label="What month would you like your NMMIP coverage to begin:"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
						}}
						flexRow
						font10
					>
						<Text>{formValues.coverageStartMonth}</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>
							Detailed information about benefits and premium rates is available
							online at{" "}
							<Link style={pdfStyles.link} src="https://www.nmmip.org">
								www.nmmip.org
							</Link>
							.
						</Text>

						<Text>Select a deductible amount for your coverage:</Text>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="$500"
								isChecked={formValues.deductible === "$500"}
							/>
							<PdfCheckbox
								label="$1,000"
								isChecked={formValues.deductible === "$1000"}
							/>
							<PdfCheckbox
								label="$2,000"
								isChecked={formValues.deductible === "$2000"}
							/>
							<PdfCheckbox
								label="$5,000"
								isChecked={formValues.deductible === "$5000"}
							/>
						</View>
					</PdfField>
				</View>

				{/* section 3 */}
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 3: Proof of Eligibility
					</Text>
					<Text style={{ ...pdfStyles.mb10 }}>
						To determine if you meet the eligibility criteria of either NMMIP's
						guidelines or those established by the Health Insurance Portability
						and Accountability Act (HIPAA), select YES or NO for{" "}
						<Text style={pdfStyles.fontBold}>every</Text> question below.
					</Text>

					{/* General Eligibility */}
					<FieldHeader header="General Eligibility" />
					<YesNoCheckbox
						label="I have been denied health insurance coverage."
						value={formValues.deniedCoverage}
					/>
					<YesNoCheckbox
						label="I am not eligible for any other form of health insurance or Medicaid."
						value={formValues.notEligibleForOtherInsurance}
					/>
					<YesNoCheckbox
						label="The premium rate for my current or applied-for individual comprehensive health insurance coverage
						exceeds the Qualifying Rate (posted on www.nmmip.org) of NMMIP's deductible plan nearest to my age, 
						tobacco status, and geographical area."
						value={formValues.exceedsQualifyingRate}
					/>

					{/* hipaa */}
					<FieldHeader
						header="Eligibility under Portability Criteria (HIPAA)"
						subheader="To be eligible under HIPAA, you must answer yes to the following three questions and provide documentation."
					/>
					<YesNoCheckbox
						label="I have had a minimum of 18 months of continuous coverage with no single gap of more than
95 days."
						value={formValues.minimum18MonthsCoverage}
					/>
					<YesNoCheckbox
						label="My last coverage was group coverage through an employer or trade union group health plan
(may or may not include COBRA)."
						value={formValues.isGroupCoverage}
					/>
					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...fieldStyles.borderRight,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
								}}
							>
								<PdfCheckbox
									label="Yes"
									isChecked={formValues.applyingWithin95Days === "Yes"}
								/>
								<PdfCheckbox
									label="No"
									isChecked={formValues.applyingWithin95Days === "No"}
								/>
							</View>
							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text>
									I am applying to NMMIP within 95 days of my prior coverage
									ending.
								</Text>
								<Text>Dates of Prior Coverage:</Text>
								<View
									style={{
										...pdfStyles.flexRow,
										...pdfStyles.gap10,
									}}
								>
									{formValues.priorCoverageStartDate && (
										<Text>
											Start Date:{" "}
											{displayFormattedDate(formValues.priorCoverageStartDate)}
										</Text>
									)}
									{formValues.priorCoverageEndDate && (
										<Text>
											End Date:{" "}
											{displayFormattedDate(formValues.priorCoverageEndDate)}
										</Text>
									)}
								</View>
							</View>
						</View>
					</PdfField>

					{/* General Exclusions */}
					<FieldHeader
						header="General Exclusions"
						subheader='If you answer "yes" to any of the following, you may not be eligible for NMMIP coverage.'
					/>
					<YesNoCheckbox
						label="I am 65 or older and eligible for Medicare."
						value={formValues.isOver65}
					/>
					<YesNoCheckbox
						label="I am eligible for Medicaid."
						value={formValues.eligibleForMedicaid}
					/>
					<YesNoCheckbox
						label="I am eligible for coverage offered by an insurance carrier or the New Mexico Health Insurance Exchange (NMHIX)."
						value={formValues.eligibleForInsuranceCarrier}
					/>
					<YesNoCheckbox
						label="I have or am eligible for an employment-related group health plan or Tricare, either as myself or as a family member."
						value={formValues.eligibleForEmploymentHealthCare}
					/>
					<YesNoCheckbox
						label="I currently have individual comprehensive health coverage. (If you have limited coverage, you may still qualify.)"
						value={formValues.hasIndividualHealthCoverage}
					/>
					<YesNoCheckbox
						label={
							"I voluntarily dropped NMMIP coverage within the last 12 months. My last date of coverage with NMMIP was: " +
							(formValues.droppedCoverageEndDate
								? new Date(formValues.droppedCoverageEndDate).toLocaleString(
										"en-US",
										{
											month: "2-digit",
											day: "2-digit",
											year: "numeric",
										}
								  )
								: "")
						}
						value={formValues.droppedNMMIPCoverage}
					/>
					<YesNoCheckbox
						label="My most recent health insurance coverage was terminated due to non-payment of premiums or fraud."
						value={formValues.terminatedInsurance}
						style={fieldStyles.borderBottom}
					/>
					<Text
						style={{
							...pdfStyles.fontBold,
							...pdfStyles.subHeader,
							...pdfStyles.textCenter,
							...pdfStyles.mt5,
						}}
					>
						Refer to the Application Checklist for required documentation.
					</Text>
				</View>
			</PdfPage>

			<PdfPage
				heading="Application for Coverage"
				pageLabel="Page 3 of 5"
				revisionLabel={revisionLabel}
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 4: Medical Information
					</Text>

					<YesNoCheckbox
						label="Do you currently use, or have you used tobacco in any form within the past 12 months?"
						value={formValues.usedTabacco}
					/>

					<YesNoCheckbox
						label={
							"Are you currently inpatient at a hospital facility? Name of Hospital: " +
							(formValues.inpatientHospital ? formValues.inpatientHospital : "")
						}
						value={formValues.inpatient}
					/>

					<PdfField
						label=""
						style={{ ...pdfStyles.wFull, ...fieldStyles.borderRight }}
					>
						<Text>
							If you have a Primary Care Physician (PCP) and/or Specialist,
							complete the information below.
						</Text>
					</PdfField>

					<PdfField label="PCP Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.pcpName}</Text>
					</PdfField>
					<PdfField
						label="PCP Phone Number (include area code)e"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.pcpPhone}</Text>
					</PdfField>
					<PdfField label="Specialist Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.specialistName}</Text>
					</PdfField>
					<PdfField
						label="Specialist Phone Number (include area code)"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.specialistPhone}</Text>
					</PdfField>

					<PdfField
						label="Check any of the following medical conditions that you have. You
							may be eligible for additional services."
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							minHeight: 85,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
								...pdfStyles.gap10,
								...pdfStyles.flex1,
								...pdfStyles.flexWrap,
								...pdfStyles.mt5,
							}}
						>
							{MedicalConditionOptions.map((condition) => (
								<PdfCheckbox
									label={condition.label}
									isChecked={(formValues.medicalConditions as any[]).includes(
										condition.label
									)}
								/>
							))}
						</View>
					</PdfField>
					<PdfField
						label="If your medical condition is not listed, please provide:"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.otherCondition}</Text>
					</PdfField>

					<PdfField
						label="How many medications do you take?"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>{formValues.medicationQuantity}</Text>
					</PdfField>
				</View>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 5: Agent, State Agency/Foundation, Third Party Sponsor
					</Text>

					<Text>
						Insurance agents in your community can assist you in completing this
						application at no cost to you.
					</Text>
					<Text style={{ ...pdfStyles.mb10 }}>
						Visit{" "}
						<Link style={pdfStyles.link} src="https://www.nmmip.org">
							www.nmmip.org
						</Link>{" "}
						for a list of brokers.
					</Text>
					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						Agent, broker, state agency, foundation, or third party sponsor of
						applicant, complete all boxes below.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.w25,
							...pdfStyles.flexRow,
						}}
					>
						<View
							style={{
								...pdfStyles.flexCol,
							}}
						>
							<PdfCheckbox
								label="Agent/Broker"
								isChecked={formValues.insuranceAgent.type === "Agent/Broker"}
							/>
							<PdfCheckbox
								label="State Agency/Foundation"
								isChecked={
									formValues.insuranceAgent.type === "State Agency/Foundation"
								}
							/>
							<PdfCheckbox
								label="Third Party Sponsor"
								isChecked={
									formValues.insuranceAgent.type === "Third Party Sponsor"
								}
							/>
						</View>
					</PdfField>

					<PdfField
						label="Name"
						style={{
							...pdfStyles.w50,
						}}
					>
						<Text>{formValues.insuranceAgent.name}</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderRight,
						}}
					>
						<Text
							style={{
								...fieldStyles.label,
							}}
						>
							Tax ID Number/
						</Text>
						<Text
							style={{
								...fieldStyles.label,
							}}
						>
							NM License Number
						</Text>
						<Text>{formValues.insuranceAgent.taxIdOrLicense}</Text>
					</PdfField>

					<PdfField label="Address" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.insuranceAgent.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.insuranceAgent.address}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w125 }}>
						<Text>{formValues.insuranceAgent.state}</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{ ...pdfStyles.w125, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.insuranceAgent.zipCode}</Text>
					</PdfField>
					<PdfField
						label="Email"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.insuranceAgent.email}</Text>
					</PdfField>
					<PdfField
						label="Phone"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.insuranceAgent.phone}</Text>
					</PdfField>
					<PdfField
						label="Fax"
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.insuranceAgent.fax}</Text>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 6: Premium Payment Information
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
							minHeight: 100,
						}}
					>
						<View
							style={{
								...pdfStyles.flexCol,
								...pdfStyles.gap10,
								...pdfStyles.flex1,
							}}
						>
							<Text>
								Select the method of payment for your initial premium (must be
								included for coverage consideration).
							</Text>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.flex1,
									...pdfStyles.fontBold,
									...pdfStyles.wFull,
									alignItems: "flex-end",
								}}
							>
								<Text>Amount $</Text>

								<View
									style={{
										...pdfStyles.w10,
										...pdfStyles.underline,
										...pdfStyles.ml3,
									}}
								>
									<Text>{formValues.paymentAmount}</Text>
								</View>
							</View>
							<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
								<PdfCheckbox
									label="Money Order or Check"
									isChecked={formValues.paymentType === "Money Order or Check"}
								/>
								<PdfCheckbox
									label="One-time ACH"
									isChecked={formValues.paymentType === "One-time ACH"}
								/>
								<PdfCheckbox
									label="Monthly ACH"
									isChecked={formValues.paymentType === "Monthly ACH"}
								/>
							</View>
							<Text>
								For one-time or monthly ACH, complete and attach Agreement for
								Preauthorized Payments (ACH Form). If paying first premium by
								check or money order, you must MAIL the application and all
								attachments WITH the payment.
							</Text>
						</View>
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading="Application for Coverage"
				pageLabel="Page 4 of 5"
				revisionLabel={revisionLabel}
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 7: Affirmation, Understanding & Disclosure Authorization
					</Text>
					<Text style={{ ...pdfStyles.fontBold }}>
						By initialing below, I acknowledge the following:
					</Text>
					<View style={{ width: "90%" }}>
						<ListItem>
							I am applying to the New Mexico Medical Insurance Pool (NMMIP) for
							an individual policy covering medical, surgical, prescription, and
							hospital insurance.
						</ListItem>
						<ListItem>
							My coverage will start on the first of the month following receipt
							of my application by NMMIP unless I am eligible for HIPAA coverage
							or continuation. If eligible for HIPAA coverage or continuation,
							my coverage will start the date that my prior group coverage
							ended.
						</ListItem>
						<ListItem>
							I will be responsible for paying premiums from my effective date
							forward.
						</ListItem>
						<ListItem>
							I affirm that all answers provided in this application are
							complete and accurate.
						</ListItem>
						<ListItem>
							No coverage will be effective until this application is accepted
							and approved, and the full initial premium has been paid.
						</ListItem>
						<ListItem>
							I have a ten-day period in which I can examine and choose to
							return the contract to have my premium refunded. If I receive
							services within that ten-day period, I must pay for those
							services.
						</ListItem>
					</View>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...pdfStyles.my10,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
								...pdfStyles.gap10,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
									...pdfStyles.alignCenter,
									...pdfStyles.justifyCenter,
									...pdfStyles.w15,
								}}
							>
								<Text style={{ ...fieldStyles.initials }}>
									{formValues.initials}
								</Text>
							</View>

							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text style={{ ...pdfStyles.fontBold }}>
									Initial here to indicate that you have read and understand the
									information listed above.
								</Text>
								<Text>
									A parent/legal guardian/personal representative must initial
									if the applicant is under 18 years of age or legally
									incompetent.
								</Text>
							</View>
						</View>
					</PdfField>

					<Text style={{ ...pdfStyles.fontBold }}>
						With my signature, I authorize the disclosure of my protected health
						information as described below:
					</Text>
					<View style={{ width: "90%", ...pdfStyles.mb10 }}>
						<ListItem listLabel="A.">
							Valid authorization to disclose nonpublic personal information
							pursuant to 13.1.3.14 NMAC shall be in written or electronic form
							separate from that used for any other purpose and shall contain
							all the following:
						</ListItem>
						<ListItem listLabel="1)" indent>
							The identity of the consumer or customer who is the subject of
							nonpublic personal information.
						</ListItem>
						<ListItem listLabel="2)" indent>
							A specific description of the types of nonpublic personal
							information to be disclosed.
						</ListItem>
						<ListItem listLabel="3)" indent>
							Specific descriptions of the parties to whom the licensee
							discloses nonpublic personal information, the purpose of the
							disclosure and how the information will be used
						</ListItem>
						<ListItem listLabel="4)" indent>
							The signature of the consumer or customer who is the subject of
							the nonpublic personal information or the individual who is
							legally empowered to grant authority and the date signed; and
						</ListItem>
						<ListItem listLabel="5)" indent>
							Notice of the length of time for which the authorization is valid,
							and that the consumer or customer may revoke the authorization at
							any time and the procedure for making a revocation.
						</ListItem>

						<ListItem listLabel="B.">
							An authorization for the purposes of this rule shall specify a
							length of time for which the authorization shall remain valid,
							which in no event shall be more than twenty-four (24) months.
						</ListItem>

						<ListItem listLabel="C.">
							A consumer or customer who is the subject of nonpublic personal
							information may revoke an authorization provided pursuant to this
							rule at any time, subject to the rights of an individual who acted
							in reliance on the authorization prior to notice of the
							revocation.
						</ListItem>

						<ListItem listLabel="D.">
							A licensee shall retain the authorization or a copy thereof in the
							record of the individual who is the subject of nonpublic personal
							information.
						</ListItem>
					</View>
					<Text style={{ ...pdfStyles.mb10 }}>
						I further acknowledge that any person who knowingly presents a false
						or fraudulent claim for payment of a loss or benefit or knowingly
						presents false information in an application for insurance is guilty
						of a crime and may be subject to civil fines and criminal penalties.
					</Text>
					<Text style={{ ...pdfStyles.mb10 }}>
						My signature below authorizes disclosure of my protected health
						information and acknowledgement as described above. Application
						cannot be processed without signature.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.w85,
							minHeight: 50,
							gap: 0,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font10,
							}}
						>
							Or parent/legal guardian/personal representative if applicant is
							under 18 years of age or legally incompetent.
						</Text>
						{formValues.affirmation.applicantSignature && (
							<Image
								style={fieldStyles.signature}
								src={formValues.affirmation.applicantSignature}
							/>
						)}
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w15,
							...fieldStyles.borderRight,
							minHeight: 70,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Today's Date
						</Text>
						<Text>
							{displayFormattedDate(formValues.affirmation.todaysDate)}
						</Text>
					</PdfField>

					<PdfField
						label="PRINTED NAME of parent/legal guardian/personal representative"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>{formValues.affirmation.legalGuardian}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
						}}
					>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Relationship to Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Attach legal document if other than Parent.
						</Text>
						<Text>{formValues.affirmation.legalGuardianRelationship}</Text>
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading="Application for Coverage"
				pageLabel="Page 5 of 5"
				revisionLabel={revisionLabel}
			>
				<View style={{ ...pdfStyles.section }}>
					<Text>
						Submit this completed and SIGNED Application for Coverage with the
						Application Checklist, ALL supporting documents, first premium
						payment, and (if applicable) LIPP Application and/or Agreement for
						Preauthorized Payments (ACH Form) by mail, email, or fax.{" "}
						<Text style={{ ...pdfStyles.fontBold }}>
							If paying first premium by check or money order, you must MAIL the
							application and all attachments WITH the payment.
						</Text>
					</Text>
					<View style={{ ...pdfStyles.contactInfo }}>
						<Text>New Mexico Medical Insurance Pool (NMMIP)</Text>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Mail:</Text>
							<Text>P.O. Box 780548, San Antonio, Tx 78278</Text>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Email:</Text>
							<Link
								style={pdfStyles.link}
								src="mailto:NMMIP_Eligibility@90degreebenefits.com"
							>
								NMMIP_Eligibility@90degreebenefits.com
							</Link>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Fax:</Text>{" "}
							<Text>210-239-8449</Text>
						</View>
					</View>
				</View>
			</PdfPage>
		</Document>
	);
};

export default HealthCareCoveragePdf;
