import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Link,
	Image,
} from "@react-pdf/renderer";
import {
	lippFormValues,
	medicareLippFormValues,
} from "../FormSchema/formInitialValues";
import PdfPage, {
	displayFormattedDate,
	fieldStyles,
	ListItem,
	PdfCheckbox,
	PdfField,
	pdfStyles,
	YesNoCheckbox,
} from "../../../components/PdfDownload/PdfTemplate";
import calculateAge from "../../../utils/calculateAge";
import { MedicalConditionOptions } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import incomeGuidelines from "../../../assets/Qualifying Income Guidelines Effective 01.01.2024 - 12.31.2024.png";
import { newAdultIncomeSource } from "../Section4/AffidavitB";

const LIPPPdf = (props: {
	formValues: typeof lippFormValues | typeof medicareLippFormValues;
	isMedicare: boolean;
}) => {
	const { formValues, isMedicare } = props;

	return (
		<Document>
			<PdfPage
				heading="Low Income Premium Program"
				heading2={isMedicare ? "Medicare Carve-Out" : "Non-Medicare"}
				pageLabel="Page 1 of 3"
				revisionLabel="Rev 01.01.2023"
			>
				<View style={{ ...pdfStyles.section }}>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						The Low Income Premium Program (LIPP) is designed to help persons
						who qualify for NMMIP coverage remain on NMMIP coverage by offering
						a reduced premium.
						<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textUnderline }}>
							Income does not determine NMMIP eligibility
						</Text>
						. You must meet the eligibility requirements on the NMMIP
						application to be eligible for coverage. To find out if you should
						apply for LIPP, find your household size and the corresponding
						yearly income amount in the table below. If:
					</Text>

					<Text>
						If you have questions or need assistance completing this
						application, please contact{" "}
						<Link style={pdfStyles.link} src="tel:+18663061882">
							1-866-306-1882
						</Link>
					</Text>
					<Text>
						or email{" "}
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
						.
					</Text>
				</View>

				<Text
					style={{
						...pdfStyles.fontBold,
						...pdfStyles.textCenter,
						...pdfStyles.mb5,
					}}
				>
					Qualifying Income Guidelines Effective 01/01/2024 - 12/31/2024
				</Text>
				<Image src={incomeGuidelines} />

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 1: Applicant Information
					</Text>
					<PdfField label="Last Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.lastName}</Text>
					</PdfField>
					<PdfField label="First Name" style={{ ...pdfStyles.w40 }}>
						<Text>{formValues.applicant.firstName}</Text>
					</PdfField>
					<PdfField
						label="MI"
						style={{ ...pdfStyles.w10, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.middleInitial}</Text>
					</PdfField>

					{/* residence address */}
					<PdfField
						label="Residence Address (Physical address required)"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.applicant.address}</Text>
					</PdfField>
					<PdfField
						label="City"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.applicant.city}</Text>
					</PdfField>
					<PdfField
						label="State"
						style={{ ...pdfStyles.w125, ...fieldStyles.borderBottom }}
					>
						<Text>NM</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{
							...pdfStyles.w125,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>{formValues.applicant.zipCode}</Text>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 2: Premium Payment Certification
					</Text>
					<Text
						style={{
							...pdfStyles.fontBold,
						}}
					>
						I certify that I, or a member of my family, will be paying my NMMIP
						coverage premiums.
					</Text>

					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						NOTE: If your premium is being paid by a third party who is not a
						family member, you are not eligible for LIPP.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 50,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>

						{formValues.lippPaymentSignature && (
							<Image
								style={fieldStyles.signature}
								src={formValues.lippPaymentSignature}
							/>
						)}
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading="Low Income Premium Program"
				heading2={isMedicare ? "Medicare Carve-Out" : "Non-Medicare"}
				pageLabel="Page 2 of 3"
				revisionLabel="Rev 01.01.2023"
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 3: Household Size
					</Text>

					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						In order to determine if you qualify for a reduced premium, we need
						information about your household size and last year's total combined
						income for all persons in your household over age 18. Even if only
						one person is enrolled in the Pool, we still need information on the
						entire household, since the premium reduction eligibility is based
						on total household income.
					</Text>

					<Text
						style={{
							...pdfStyles.mb5,
						}}
					>
						List all the people in your household. Use additional sheets, if
						needed, for more household members.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wThird,
							...fieldStyles.fieldSmall,
						}}
					>
						<Text
							style={{
								...pdfStyles.font10,
								...pdfStyles.fontBold,
								...pdfStyles.bgGray,
							}}
						>
							Name
						</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wThird,
							...fieldStyles.fieldSmall,
						}}
					>
						<Text
							style={{
								...pdfStyles.font10,
								...pdfStyles.fontBold,
								...pdfStyles.bgGray,
							}}
						>
							Relationship
						</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wThird,
							...fieldStyles.fieldSmall,
							...fieldStyles.borderRight,
							...pdfStyles.bgGray,
						}}
					>
						<Text
							style={{
								...pdfStyles.font10,
								...pdfStyles.fontBold,
							}}
						>
							Date of Birth
						</Text>
					</PdfField>

					{Array.from(Array(8).keys()).map((member, i) => (
						<>
							<PdfField
								label=""
								style={{
									...pdfStyles.wThird,
									...fieldStyles.fieldSmall,
									...(i === 7 && { ...fieldStyles.borderBottom }),
								}}
							>
								{formValues.household.length > i ? (
									<Text>{formValues.household[i].name}</Text>
								) : (
									<></>
								)}
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.wThird,
									...fieldStyles.fieldSmall,
									...(i === 7 && { ...fieldStyles.borderBottom }),
								}}
							>
								{formValues.household.length > i ? (
									<Text>{formValues.household[i].relationship}</Text>
								) : (
									<></>
								)}
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.wThird,
									...fieldStyles.fieldSmall,
									...fieldStyles.borderRight,
									...(i === 7 && { ...fieldStyles.borderBottom }),
								}}
							>
								{formValues.household.length > i ? (
									<Text>
										{formValues.household[i].birthDate
											? displayFormattedDate(formValues.household[i].birthDate)
											: ""}
									</Text>
								) : (
									<></>
								)}
							</PdfField>
						</>
					))}
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 4: Household Income Information and Verification
					</Text>

					{isMedicare ? (
						<>
							<Text style={{ ...pdfStyles.mb10 }}>
								<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.mb10 }}>
									NOTE:{" "}
								</Text>
								You do <Text style={{ ...pdfStyles.textUnderline }}>not</Text>{" "}
								need to include income information or verification for any
								member of your household whose income is from Supplementary
								Security Income (SSI) and/or Temporary Assistance for Needy
								Families (TANF){" "}
								<Text style={{ ...pdfStyles.fontBold }}>ONLY</Text>.
							</Text>

							<Text>
								List total annual income amount for adults in your household{" "}
								<Text style={{ ...pdfStyles.textUnderline }}>
									except as excluded above
								</Text>
							</Text>

							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-end",
									gap: 10,
									...pdfStyles.mb10,
								}}
							>
								<Text>(from Federal Tax Form: Line 7b of Form 1040)</Text>
								<View
									style={{
										...pdfStyles.w35,
										...pdfStyles.underline,
									}}
								>
									<Text>$</Text>
								</View>
							</View>
						</>
					) : (
						<>
							{" "}
							<Text>
								<Text style={{ ...pdfStyles.fontBold }}>NOTE: </Text>
								For the 2024 verification process, the New Mexico Medical
								Insurance Pool will be using the Modified Adjusted Gross Income
								(MAGI) method to determine your eligibility for the Low Income
								Premium Program (LIPP). MAGI is adjusted gross income (AGI) plus
								these, if any: untaxed foreign income, non-taxable Social
								Security benefits, and tax-exempt interest. Follow these basic
								rules when including members of your household:
							</Text>
							<View style={{ width: "90%" }}>
								<ListItem>
									Include your spouse if you're legally married
								</ListItem>
								<ListItem>
									If you plan to claim someone as a tax dependent for the year
									you want coverage, do include them on your application
								</ListItem>
								<ListItem>
									If you won't claim them as a tax dependent, don't include them
								</ListItem>
								<ListItem>
									Include your spouse and tax dependents even if they don't need
									health coverage
								</ListItem>
							</View>
							<Text style={{ ...pdfStyles.mb10 }}>
								For more information, please go to{" "}
								<Link
									style={pdfStyles.link}
									src="https://www.healthcare.gov/income-and-household-information"
								>
									www.healthcare.gov/income-and-household-information
								</Link>
							</Text>
							<Text>
								Adjusted Gross Income (AGI): As defined by the IRS, AGI is gross
								income minus adjustments to income
							</Text>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-end",
									gap: 10,
									...pdfStyles.mb5,
								}}
							>
								<View
									style={{
										...pdfStyles.w5,
										...pdfStyles.underline,
									}}
								>
									<Text>
										${(formValues as typeof lippFormValues).adjustedGrossIncome}
									</Text>
								</View>
								<Text style={{ ...pdfStyles.font8 }}>
									This can be found on Line 11 of the 2022 1040 tax filing form
								</Text>
							</View>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-end",
									gap: 10,
									...pdfStyles.mb5,
								}}
							>
								<View
									style={{
										...pdfStyles.w5,
										...pdfStyles.underline,
									}}
								>
									<Text>
										$
										{
											(formValues as typeof lippFormValues)
												.excludedForeignIncome
										}
									</Text>
								</View>
								<Text>
									Excluded Foreign Income: Foreign earned income excluded from
									taxation of individuals who live abroad
								</Text>
							</View>
							<Text>
								Non-Taxable Social Security Benefits: Social Security benefits
								not included in gross income
							</Text>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-end",
									gap: 10,
									...pdfStyles.mb5,
								}}
							>
								<View
									style={{
										...pdfStyles.w5,
										...pdfStyles.underline,
									}}
								>
									<Text>
										$
										{
											(formValues as typeof lippFormValues)
												.nonTaxableSocialSecurityBenefits
										}
									</Text>
								</View>
								<Text style={{ ...pdfStyles.font8 }}>
									This can be found on Line 6a of the 2022 1040 tax filing form
								</Text>
							</View>
							<Text>
								Tax-Exempt Interest: Interest income that is not subject to
								federal income tax
							</Text>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-end",
									gap: 10,
									...pdfStyles.mb5,
								}}
							>
								<View
									style={{
										...pdfStyles.w5,
										...pdfStyles.underline,
									}}
								>
									<Text>
										${(formValues as typeof lippFormValues).taxExemptInterest}
									</Text>
								</View>
								<Text style={{ ...pdfStyles.font8 }}>
									This can be found on Line 2a of the 2022 1040 tax filing form
								</Text>
							</View>
							<Text>
								Adjusted Gross Income (AGI) + Non-Taxable Social Security
								Benefits + Tax-Exempt Interest + Excluded Foreign Income
							</Text>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-end",
									gap: 10,
									...pdfStyles.mb10,
								}}
							>
								<View
									style={{
										...pdfStyles.w5,
										...pdfStyles.underline,
									}}
								>
									<Text>
										$
										{(formValues as typeof lippFormValues)
											.modifiedAdjustedGrossIncome ? (
											(formValues as typeof lippFormValues)
												.modifiedAdjustedGrossIncome
										) : (
											<></>
										)}
									</Text>
								</View>
								<Text>= Modified Adjusted Gross Income (MAGI)</Text>
							</View>
						</>
					)}

					<Text style={{ ...pdfStyles.fontBold }}>In addition,</Text>
					<View style={{ width: "90%" }}>
						<ListItem listLabel="1.">
							Attach a copy of the previous year's Federal Income Tax forms
							filed (include certification form if filed electronically) by each
							household member who had income, except as excluded above, and
							complete and sign the{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								Affidavit A - Federal Tax Form Affidavit
							</Text>{" "}
							portion of this application{" "}
							<Text style={{ ...pdfStyles.fontBold }}>- AND/OR -</Text>
						</ListItem>
						<ListItem listLabel="2.">
							If any adult in your household had income, except as excluded
							above, but were not required to file a Federal Income Tax form,
							they must complete, sign and{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								notarize the Affidavit B - Other Income Source Affidavit
							</Text>{" "}
							portion of this application.
						</ListItem>
					</View>
				</View>
			</PdfPage>

			{/* Affidavit A */}
			<PdfPage
				heading="Low Income Premium Program"
				heading2={isMedicare ? "Medicare Carve-Out" : "Non-Medicare"}
				pageLabel="Page 3 of 5"
				revisionLabel="Rev 01.01.2023"
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Affidavit A - Federal Tax Form Affidavit
					</Text>

					<Text style={{ ...pdfStyles.mb10 }}>
						Make copies for other household members, if necessary.{" "}
						<Text style={{ ...pdfStyles.fontBold }}>
							Not Required to be Notarized.
						</Text>
					</Text>

					<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.mb10 }}>
						By my signature, I swear or affirm that the attached tax form is a
						true reflection of my income for calendar year 2024, and is a
						correct copy of the form provided to the Internal Revenue Service
						(IRS).
					</Text>

					<Text style={{ ...pdfStyles.mb10 }}>
						I certify that the foregoing answers are true and accurate to the
						best of my knowledge and belief. I also acknowledge that NMMIP may
						verify this information with state agencies and other sources.
					</Text>
					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							minHeight: 100,
							gap: 0,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font10,
							}}
						>
							Or parent/legal guardian/personal representative if applicant is
							under 18 years of age or legally incompetent.
						</Text>
						{formValues.affidavitA.signature && (
							<Image
								style={fieldStyles.signature}
								src={formValues.affidavitA.signature}
							/>
						)}
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w35,
							minHeight: 100,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Printed Name of Applicant
						</Text>
						<Text>{formValues.affidavitA.applicantName}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w15,
							...fieldStyles.borderRight,
							minHeight: 100,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Today's Date
						</Text>
						<Text>
							{displayFormattedDate(formValues.affidavitA.todaysDate)}
						</Text>
					</PdfField>

					<PdfField
						label="PRINTED NAME of parent/legal guardian/personal representative"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							minHeight: 50,
						}}
					>
						<Text>{formValues.affidavitA.legalGuardian}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 50,
						}}
					>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Relationship to Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Attach legal document if other than Parent.
						</Text>
						<Text>{formValues.affidavitA.legalGuardianRelationship}</Text>
					</PdfField>
				</View>
			</PdfPage>

			{/* Affidavit B*/}
			{(formValues.affidavitB.length > 0
				? formValues.affidavitB
				: [newAdultIncomeSource]
			).map((householdMember) => (
				<PdfPage
					heading="Low Income Premium Program"
					heading2={isMedicare ? "Medicare Carve-Out" : "Non-Medicare"}
					pageLabel="Page 4 of 5"
					revisionLabel="Rev 01.01.2023"
				>
					<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
						<Text style={{ ...fieldStyles.heading }}>
							Affidavit B - Other Income Source Affidavit
						</Text>

						<Text style={{ ...pdfStyles.mb10 }}>
							Make copies for other household members, if necessary.
						</Text>

						<Text style={{ ...pdfStyles.mb10 }}>
							The individual who was not required to file a Federal Tax Income
							Form must complete and sign Other Income Source Affidavit B below.
						</Text>

						<PdfField label="Last Name" style={{ ...pdfStyles.w50 }}>
							<Text>{householdMember.lastName}</Text>
						</PdfField>
						<PdfField label="First Name" style={{ ...pdfStyles.w40 }}>
							<Text>{householdMember.firstName}</Text>
						</PdfField>
						<PdfField
							label="MI"
							style={{ ...pdfStyles.w10, ...fieldStyles.borderRight }}
						>
							<Text>{householdMember.middleInitial}</Text>
						</PdfField>

						{/* contact info */}
						<PdfField
							label="Home Phone"
							style={{ ...pdfStyles.w20, ...fieldStyles.borderBottom }}
						>
							<Text>{householdMember.homePhone}</Text>
						</PdfField>
						<PdfField
							label="Work Phone"
							style={{ ...pdfStyles.w20, ...fieldStyles.borderBottom }}
						>
							<Text>{householdMember.workPhone}</Text>
						</PdfField>
						<PdfField
							label="Cell Phone"
							style={{ ...pdfStyles.w20, ...fieldStyles.borderBottom }}
						>
							<Text>{householdMember.cellPhone}</Text>
						</PdfField>
						<PdfField
							label="Email Address"
							style={{
								...pdfStyles.w40,
								...fieldStyles.borderRight,
								...fieldStyles.borderBottom,
							}}
						>
							<Text>{householdMember.email}</Text>
						</PdfField>

						{/* income source table */}
						<View
							style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}
						>
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
									...pdfStyles.bgGray,
								}}
							>
								<Text
									style={{
										...pdfStyles.font10,
										...pdfStyles.fontBold,
									}}
								>
									Income Source Description
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
									...fieldStyles.borderRight,
									...pdfStyles.bgGray,
								}}
							>
								<Text
									style={{
										...pdfStyles.font10,
										...pdfStyles.fontBold,
									}}
								>
									Income Amount
								</Text>
							</PdfField>
							{Array.from(Array(6).keys()).map((income, i) => (
								<>
									<PdfField
										label=""
										style={{
											...pdfStyles.w50,
											...fieldStyles.fieldSmall,
										}}
									>
										{householdMember.incomeSources.length > i ? (
											<Text>
												{householdMember.incomeSources[i].description}
											</Text>
										) : (
											<></>
										)}
									</PdfField>
									<PdfField
										label=""
										style={{
											...pdfStyles.w50,
											...fieldStyles.fieldSmall,
											...fieldStyles.borderRight,
										}}
									>
										{householdMember.incomeSources.length > i ? (
											<Text>${householdMember.incomeSources[i].amount}</Text>
										) : (
											<Text>$</Text>
										)}
									</PdfField>
								</>
							))}
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
									...fieldStyles.borderBottom,
								}}
							>
								<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textRight }}>
									Total:
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
									...pdfStyles.bgGray,
									...fieldStyles.borderRight,
									...fieldStyles.borderBottom,
								}}
							>
								<Text>$</Text>
							</PdfField>
						</View>

						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.mb10,
							}}
						>
							By my signature, I swear or affirm that I am not required to file
							a Federal Income Tax return for calendar year 2024, and that my
							income for that calendar year was as noted above.
						</Text>

						<PdfField
							label=""
							style={{
								...pdfStyles.w50,
								...fieldStyles.borderBottom,
								minHeight: 50,
								gap: 0,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Signature
							</Text>

							{householdMember.signature && (
								<Image
									style={fieldStyles.signature}
									src={householdMember.signature}
								/>
							)}
						</PdfField>

						<PdfField
							label=""
							style={{
								...pdfStyles.w30,
								minHeight: 50,
								...fieldStyles.borderBottom,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Printed Name of Applicant
							</Text>
						</PdfField>

						<PdfField
							label=""
							style={{
								...pdfStyles.w20,
								...fieldStyles.borderRight,
								...fieldStyles.borderBottom,
								minHeight: 50,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Today's Date
							</Text>
							<Text>
								{householdMember.todaysDate &&
									displayFormattedDate(householdMember.todaysDate)}
							</Text>
						</PdfField>

						{/* notary section */}
						<View style={{ marginTop: 50 }}>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-start",
									gap: 10,
									marginBottom: 15,
								}}
							>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									Subscribed and sworn before me this
								</Text>
								<View
									style={{
										...pdfStyles.w5,
										...pdfStyles.underline,
										...pdfStyles.mb12,
									}}
								></View>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									day of
								</Text>
								<View
									style={{
										...pdfStyles.w15,
										...pdfStyles.underline,
										...pdfStyles.mb12,
									}}
								></View>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									by
								</Text>
								<View
									style={{
										...pdfStyles.w30,
									}}
								>
									<View
										style={{
											...pdfStyles.underline,
										}}
									></View>
									<Text
										style={{
											...pdfStyles.textCenter,
										}}
									>
										(Name)
									</Text>
								</View>
							</View>

							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-start",
									gap: 10,
								}}
							>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									My commission expires:
								</Text>
								<View
									style={{
										...pdfStyles.w25,
										...pdfStyles.underline,
									}}
								></View>
								<View
									style={{
										...pdfStyles.w50,
									}}
								>
									<View
										style={{
											...pdfStyles.underline,
										}}
									></View>
									<Text
										style={{
											...pdfStyles.textCenter,
										}}
									>
										Notary Public
									</Text>
								</View>
							</View>
						</View>
					</View>
				</PdfPage>
			))}

			{/* Affidavit C */}
			<PdfPage
				heading="Low Income Premium Program"
				heading2={isMedicare ? "Medicare Carve-Out" : "Non-Medicare"}
				pageLabel="Page 5 of 5"
				revisionLabel="Rev 01.01.2023"
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Affidavit C - Income Change Affidavit
					</Text>

					<PdfField
						label="Current Annual Household Income Total:"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							minHeight: 50,
						}}
					>
						<Text>{formValues.affidavitC.currentAnnualHouseholdIncome}</Text>
					</PdfField>
					<PdfField
						label="Reason for Difference between most recent Tax Filing and Current Household Income:"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 50,
						}}
					>
						<Text>{formValues.affidavitC.explanationForIncomeDifference}</Text>
					</PdfField>

					<Text
						style={{
							...pdfStyles.fontBold,
							...pdfStyles.my10,
						}}
					>
						By my signature, I swear or affirm that my current income for
						calendar year 2024 is as noted above.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 100,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>

						{formValues.affidavitC.signature && (
							<Image
								style={fieldStyles.signature}
								src={formValues.affidavitC.signature}
							/>
						)}
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section }}>
					<View style={{ ...pdfStyles.contactInfo }}>
						<Text>New Mexico Medical Insurance Pool (NMMIP)</Text>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Mail:</Text>
							<Text>P.O. Box 780548, San Antonio, Tx 78278</Text>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Email:</Text>
							<Link
								style={pdfStyles.link}
								src="mailto:NMMIP_Eligibility@90degreebenefits.com"
							>
								NMMIP_Eligibility@90degreebenefits.com
							</Link>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Fax:</Text>{" "}
							<Text>210-239-8449</Text>
						</View>
					</View>
				</View>
			</PdfPage>
		</Document>
	);
};

export default LIPPPdf;

export const TestPdf = () => {
	return (
		<Document>
			<PdfPage
				heading="Low Income Premium Program"
				heading2={"Non-Medicare"}
				pageLabel="Page 3 of 5"
				revisionLabel="Rev 01.01.2023"
			>
				<View style={{ ...pdfStyles.section, ...pdfStyles.mt10 }}>
					<View
						style={{
							...pdfStyles.flexRow,
							...pdfStyles.wFull,
							alignItems: "flex-start",
							gap: 10,
							marginBottom: 15,
						}}
					>
						<Text
							style={{
								position: "relative",
								top: -12,
							}}
						>
							Subscribed and sworn before me this
						</Text>
						<View
							style={{
								...pdfStyles.w5,
								...pdfStyles.underline,
								...pdfStyles.mb12,
							}}
						></View>
						<Text
							style={{
								position: "relative",
								top: -12,
							}}
						>
							day of
						</Text>
						<View
							style={{
								...pdfStyles.w15,
								...pdfStyles.underline,
								...pdfStyles.mb12,
							}}
						></View>
						<Text
							style={{
								position: "relative",
								top: -12,
							}}
						>
							by
						</Text>
						<View
							style={{
								...pdfStyles.w30,
							}}
						>
							<View
								style={{
									...pdfStyles.underline,
								}}
							></View>
							<Text
								style={{
									...pdfStyles.textCenter,
								}}
							>
								(Name)
							</Text>
						</View>
					</View>

					<View
						style={{
							...pdfStyles.flexRow,
							...pdfStyles.wFull,
							alignItems: "flex-start",
							gap: 10,
						}}
					>
						<Text
							style={{
								position: "relative",
								top: -12,
							}}
						>
							My commission expires:
						</Text>
						<View
							style={{
								...pdfStyles.w25,
								...pdfStyles.underline,
							}}
						></View>
						<View
							style={{
								...pdfStyles.w50,
							}}
						>
							<View
								style={{
									...pdfStyles.underline,
								}}
							></View>
							<Text
								style={{
									...pdfStyles.textCenter,
								}}
							>
								Notary Public
							</Text>
						</View>
					</View>
				</View>
			</PdfPage>
		</Document>
	);
};
