import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import Button from "../../../components/Button/Button";

export const schema = {
	values: {},
	validation: Yup.object().shape({}),
};

const Intro = () => {
	return (
		<>
			<p>
				The Low Income Premium Program (LIPP) is designed to help persons who
				qualify for New Mexico Medical Insurance Pool (NMMIP) coverage by
				offering a reduced premium. Income does not determine NMMIP eligibility.
				If you meet the LIPP eligibility requirements, you must complete and
				submit this form.
				<br /> <br />
				<span className="underline">
					If your premium is paid by a third party who is not a family member,
					you are not eligible for LIPP.
				</span>
				<br /> <br />
				For assistance, contact us at 1-866-306-1882 or email{" "}
				<a href="mailto:NMMIP_Eligibility@90degreebenefits.com">
					NMMIP_Eligibility@90degreebenefits.com
				</a>
				.
			</p>
			<div className="flex flex-wrap gap-[1rem]">
				<Button
					type="button"
					href={
						"https://nmmip.org/eligibility-and-coverage/low-income-premium-program-lipp/"
					}
				>
					View LIPP Qualification Guidelines
				</Button>
				<Button
					type="button"
					href={"https://nmmip.org/eligibility-and-coverage/2024-rate-tables/"}
				>
					View LIPP Rate Tables
				</Button>
			</div>
		</>
	);
};

export default Intro;
