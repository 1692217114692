import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css } from "styled-components";
import {
	countyOptions,
	householdSizeOptions,
	incomeThresholdMap,
	povertyGuidelineOptions,
} from "../../LIPP/FormSchema/formInitialValues";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		deductible: "",
	},
	validation: Yup.object().shape({
		deductible: Yup.string().required(requiredWarning),
	}),
};

const Deductible = () => {
	return (
		<>
			<p>
				Newborn coverage is based on the coverage of the NMMIP enrollee's
				coverage. To determine rates and the amount you would pay for insurance
				through NMMIP, view the rate table information. Newborns qualify for the
				“0-14” rate.
				<br /> <br />
				First payment <span className="underline">must</span> accompany the
				application to be considered for coverage.
			</p>

			<Button
				type="button"
				href={
					"https://nmmip.org/eligibility-and-coverage/2024-rate-tables/2024-qualifying-rates/"
				}
			>
				View Rate Table
			</Button>

			<StyledLegend>
				<BlueLegendHeading>
					Select a deductible amount for your coverage:
				</BlueLegendHeading>

				<Dropdown
					className="!max-w-[700px]"
					name="deductible"
					label="Select an Amount"
					options={DeductibleAmounts}
					required
				/>
			</StyledLegend>
		</>
	);
};

export default Deductible;
