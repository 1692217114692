const Submission = () => {
	return (
		<>
			<b>
				You will be notified once a decision regarding your application has been
				made.
			</b>
			<p>
				For assistance, contact us at 1-866-306-1882 or email{" "}
				<span className="underline">
					NMMIP_Eligibility@90degreebenefits.com
				</span>
				.
			</p>
		</>
	);
};

export default Submission;
