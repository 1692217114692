import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Link,
	Image,
} from "@react-pdf/renderer";
import { medicareFormValues } from "../FormSchema/formInitialValues";
import PdfPage, {
	displayFormattedDate,
	fieldStyles,
	PdfCheckbox,
	PdfField,
	pdfStyles,
	YesNoCheckbox,
} from "../../../components/PdfDownload/PdfTemplate";
import calculateAge from "../../../utils/calculateAge";
import { MedicalConditionOptions } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const MedicarePdf = (props: { formValues: typeof medicareFormValues }) => {
	const { formValues } = props;

	return (
		<Document>
			<PdfPage
				heading="Application for Coverage"
				heading2="Medicare Carve-Out"
				pageLabel="Page 1 of 2"
				revisionLabel="Rev 01.01.2020"
			>
				<View style={{ ...pdfStyles.section }}>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						To be eligible for the Medicare Carve-Out plan, you must be under
						age 65 and be enrolled in Medicare Parts A{" "}
						<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textUnderline }}>
							and
						</Text>{" "}
						B due to disability.
					</Text>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						NOTE: Every person applying for a New Mexico Medical Insurance Pool
						policy, even if in the same family, must complete a separate
						application.
					</Text>

					<Text>
						If you have questions or need assistance completing this
						application, please contact{" "}
						<Link style={pdfStyles.link} src="tel:+18663061882">
							1-866-306-1882
						</Link>
					</Text>
					<Text>
						or email{" "}
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
						.
					</Text>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 1: Applicant Information
					</Text>
					<PdfField label="Last Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.lastName}</Text>
					</PdfField>
					<PdfField label="First Name" style={{ ...pdfStyles.w40 }}>
						<Text>{formValues.applicant.firstName}</Text>
					</PdfField>
					<PdfField
						label="MI"
						style={{ ...pdfStyles.w10, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.middleInitial}</Text>
					</PdfField>
					<PdfField label="Age" style={{ ...pdfStyles.w10 }}>
						<Text>
							{formValues.applicant.birthDate &&
								calculateAge(formValues.applicant.birthDate)}
						</Text>
					</PdfField>
					<PdfField
						label="Birth Date (MM/DD/YYYY)"
						style={{ ...pdfStyles.w40 }}
					>
						{displayFormattedDate(formValues.applicant.birthDate)}
					</PdfField>
					<PdfField label="Gender" style={{ ...pdfStyles.w10 }}>
						<Text>{formValues.applicant.gender}</Text>
					</PdfField>
					<PdfField
						label="Social Security Number (if applicable)"
						style={{ ...pdfStyles.w40, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.ssn}</Text>
					</PdfField>

					{/* residence address */}
					<PdfField
						label="Residence Address (Physical address required)"
						style={{ ...pdfStyles.w35 }}
					>
						<Text>{formValues.residence.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.residence.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField label="Zip" style={{ ...pdfStyles.w15 }}>
						<Text>{formValues.residence.zipCode}</Text>
					</PdfField>
					<PdfField
						label="County"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.residence.county}</Text>
					</PdfField>

					{/* mailing address */}
					<PdfField label="Mailing Address" style={{ ...pdfStyles.w35 }}>
						<Text>{formValues.mailing.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.mailing.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField label="Zip" style={{ ...pdfStyles.w15 }}>
						<Text>{formValues.mailing.zipCode}</Text>
					</PdfField>
					<PdfField
						label="County"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.mailing.county}</Text>
					</PdfField>

					{/* contact info */}
					<PdfField
						label="Email Address (optional)"
						style={{ ...pdfStyles.w40 }}
					>
						<Text>{formValues.applicant.email}</Text>
					</PdfField>
					<PdfField label="Home Phone" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.applicant.homePhone}</Text>
					</PdfField>
					<PdfField label="Work Phone" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.applicant.workPhone}</Text>
					</PdfField>
					<PdfField
						label="Cell Phone"
						style={{ ...pdfStyles.w20, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.cellPhone}</Text>
					</PdfField>

					<PdfField label="Preferred Language" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.preferredLanguage}</Text>
					</PdfField>
					<PdfField
						label="Preferred Method of Communication"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderRight }}
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Phone"
								isChecked={
									formValues.applicant.preferredCommunication === "Phone"
								}
							/>
							<PdfCheckbox
								label="Text"
								isChecked={
									formValues.applicant.preferredCommunication === "Text"
								}
							/>
							<PdfCheckbox
								label="Email"
								isChecked={
									formValues.applicant.preferredCommunication === "Email"
								}
							/>
						</View>
					</PdfField>

					<PdfField
						label="I am a resident of the state of New Mexico."
						style={{ ...pdfStyles.wFull, ...fieldStyles.borderRight }}
						flexRow
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Yes"
								isChecked={formValues.residence.state === "New Mexico"}
							/>
							<PdfCheckbox
								label="No"
								isChecked={
									formValues.residence.state !== "" &&
									formValues.residence.state !== "New Mexico"
								}
							/>
						</View>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text
							style={{
								...pdfStyles.wFull,
								...pdfStyles.fontBold,
							}}
						>
							I understand the first month's premium must be included with the
							application.
						</Text>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 2. Qualifying Conditions
					</Text>
					<YesNoCheckbox
						label="I am under 65 years of age and enrolled in Medicare due to a disability."
						value={formValues.isUnder65AndDisabled}
					/>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...fieldStyles.borderRight,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
								}}
							>
								<PdfCheckbox
									label="Yes"
									isChecked={formValues.haveMedicarePartAandB === "Yes"}
								/>
								<PdfCheckbox
									label="No"
									isChecked={formValues.haveMedicarePartAandB === "No"}
								/>
							</View>
							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text
									style={{
										...pdfStyles.mb5,
									}}
								>
									I have Medicare (copy of your award letter or Medicare Card is
									required with this application).
								</Text>

								<View
									style={{
										...pdfStyles.flexRow,
										...pdfStyles.gap10,
									}}
								>
									<Text>
										Part A Effective Date:{" "}
										{formValues.partAEffectiveDate &&
											displayFormattedDate(formValues.partAEffectiveDate)}
									</Text>

									<Text>
										Part B Effective Date:{" "}
										{formValues.partBEffectiveDate &&
											displayFormattedDate(formValues.partBEffectiveDate)}
									</Text>
								</View>
							</View>
						</View>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...fieldStyles.borderRight,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
								}}
							>
								<PdfCheckbox
									label="Yes"
									isChecked={
										formValues.haveInsuranceOtherThanMedicaid === "Yes"
									}
								/>
								<PdfCheckbox
									label="No"
									isChecked={formValues.haveInsuranceOtherThanMedicaid === "No"}
								/>
							</View>
							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text
									style={{
										...pdfStyles.mb5,
									}}
								>
									I have other insurance (other than Medicare).
								</Text>

								<Text>
									Name of Insurance Company:{" "}
									{formValues.insuranceCompanyDetails.insuranceCompanyName}
								</Text>

								<Text>
									Coverage End Date:{" "}
									{formValues.insuranceCompanyDetails.coverageEndDate &&
										displayFormattedDate(
											formValues.insuranceCompanyDetails.coverageEndDate
										)}
								</Text>

								<Text>
									Reason for Termination:{" "}
									{formValues.insuranceCompanyDetails.whyIsCoverageEnding}
								</Text>
							</View>
						</View>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
								}}
							>
								<PdfCheckbox
									label="Yes"
									isChecked={
										formValues.haveInsuranceOtherThanMedicaid === "Yes"
									}
								/>
								<PdfCheckbox
									label="No"
									isChecked={formValues.haveInsuranceOtherThanMedicaid === "No"}
								/>
							</View>
							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text
									style={{
										...pdfStyles.mb5,
									}}
								>
									I have been covered by NMMIP in the past.
								</Text>

								<Text>
									Coverage Start Date:{" "}
									{formValues.nmmipCoverageDetails.priorCoverageStartDate &&
										displayFormattedDate(
											formValues.nmmipCoverageDetails.priorCoverageStartDate
										)}
								</Text>

								<Text>
									Coverage End Date:{" "}
									{formValues.nmmipCoverageDetails.priorCoverageEndDate &&
										displayFormattedDate(
											formValues.nmmipCoverageDetails.priorCoverageEndDate
										)}
								</Text>

								<Text>
									Reason for Termination:{" "}
									{formValues.nmmipCoverageDetails.reasonForTermination}
								</Text>
							</View>
						</View>
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading="Application for Coverage"
				heading2="Medicare Carve-Out"
				pageLabel="Page 2 of 2"
				revisionLabel="Rev 01.01.2020"
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<PdfField
						label="Check any of the following medical conditions that you have. You
							may be eligible for additional services."
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							minHeight: 85,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
								...pdfStyles.gap10,
								...pdfStyles.flex1,
								...pdfStyles.flexWrap,
								...pdfStyles.mt5,
							}}
						>
							{MedicalConditionOptions.map((condition) => (
								<PdfCheckbox
									label={condition.label}
									isChecked={(formValues.medicalConditions as any[]).includes(
										condition.label
									)}
								/>
							))}
						</View>
					</PdfField>
					<PdfField
						label="If your medical condition is not listed, please provide:"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.otherCondition}</Text>
					</PdfField>

					<PdfField
						label="How many medications do you take?"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>{formValues.medicationQuantity}</Text>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 3: Agent, State Agency/Foundation, Third Party Sponsor
					</Text>

					<Text>
						Insurance agents in your community can assist you in completing this
						application at no cost to you.
					</Text>
					<Text style={{ ...pdfStyles.mb10 }}>
						Visit{" "}
						<Link style={pdfStyles.link} src="https://www.nmmip.org">
							www.nmmip.org
						</Link>{" "}
						for a list of brokers.
					</Text>
					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						Agent, broker, state agency, foundation, or third party sponsor of
						applicant, complete all boxes below.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.w25,
							...pdfStyles.flexRow,
						}}
					>
						<View
							style={{
								...pdfStyles.flexCol,
							}}
						>
							<PdfCheckbox
								label="Agent/Broker"
								isChecked={formValues.insuranceAgent.type === "Agent/Broker"}
							/>
							<PdfCheckbox
								label="State Agency/Foundation"
								isChecked={
									formValues.insuranceAgent.type === "State Agency/Foundation"
								}
							/>
							<PdfCheckbox
								label="Third Party Sponsor"
								isChecked={
									formValues.insuranceAgent.type === "Third Party Sponsor"
								}
							/>
						</View>
					</PdfField>

					<PdfField
						label="Name"
						style={{
							...pdfStyles.w50,
						}}
					>
						<Text>{formValues.insuranceAgent.name}</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderRight,
						}}
					>
						<Text
							style={{
								...fieldStyles.label,
							}}
						>
							Tax ID Number/
						</Text>
						<Text
							style={{
								...fieldStyles.label,
							}}
						>
							NM License Number
						</Text>
						<Text>{formValues.insuranceAgent.taxIdOrLicense}</Text>
					</PdfField>

					<PdfField label="Address" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.insuranceAgent.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.insuranceAgent.address}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w125 }}>
						<Text>{formValues.insuranceAgent.state}</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{ ...pdfStyles.w125, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.insuranceAgent.zipCode}</Text>
					</PdfField>
					<PdfField
						label="Email"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.insuranceAgent.email}</Text>
					</PdfField>
					<PdfField
						label="Phone"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.insuranceAgent.phone}</Text>
					</PdfField>
					<PdfField
						label="Fax"
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.insuranceAgent.fax}</Text>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Section 4: Affirmation, Understanding & Disclosure Authorization
					</Text>
					<Text
						style={{
							...pdfStyles.wFull,
						}}
					>
						I certify that the foregoing statements are true and accurate to the
						best of my knowledge and belief. I understand that no coverage will
						be effective until the full initial premium is paid and this
						application has been approved by the NMMIP Administrator. I
						understand that if I obtain or become eligible for health coverage,
						I will notify the NMMIP Administrator of the other coverage.
					</Text>
				</View>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<PdfField
						label=""
						style={{
							...pdfStyles.w85,
							minHeight: 50,
							gap: 0,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font10,
							}}
						>
							Or parent/legal guardian/personal representative if applicant is
							under 18 years of age or legally incompetent.
						</Text>
						{formValues.affirmation.applicantSignature && (
							<Image
								style={fieldStyles.signature}
								src={formValues.affirmation.applicantSignature}
							/>
						)}
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w15,
							...fieldStyles.borderRight,
							minHeight: 70,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Today's Date
						</Text>
						<Text>
							{displayFormattedDate(formValues.affirmation.todaysDate)}
						</Text>
					</PdfField>

					<PdfField
						label="PRINTED NAME of parent/legal guardian/personal representative"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>{formValues.affirmation.legalGuardian}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
						}}
					>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Relationship to Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Attach legal document if other than Parent.
						</Text>
						<Text>{formValues.affirmation.legalGuardianRelationship}</Text>
					</PdfField>

					<View style={{ ...pdfStyles.contactInfo }}>
						<Text>New Mexico Medical Insurance Pool (NMMIP)</Text>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Mail:</Text>
							<Text>P.O. Box 780548, San Antonio, Tx 78278</Text>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Email:</Text>
							<Link
								style={pdfStyles.link}
								src="mailto:NMMIP_Eligibility@90degreebenefits.com"
							>
								NMMIP_Eligibility@90degreebenefits.com
							</Link>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Fax:</Text>{" "}
							<Text>210-239-8449</Text>
						</View>
					</View>
				</View>
			</PdfPage>
		</Document>
	);
};

export default MedicarePdf;
