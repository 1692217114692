import styled, { css } from "styled-components";
import FormikField, {
	Error,
	getPlaceholder,
	StyledFieldWrapper,
} from "./Field";
import { FieldType } from "./fieldTypes";
import { useField } from "formik";

const TextArea = (props: TextAreaProps) => {
	const { name, label, required, type, className, value, maxLength, rows } =
		props;
	const [field, meta, helpers] = useField(props);

	const hasvalue =
		(field.value !== null && field.value !== undefined && field.value !== "") ||
		(value !== null && value !== undefined && value !== "");

	return (
		<StyledFieldWrapper
			className={`${className ? className : ""}`}
			type={type}
			haserror={meta.touched && meta.error ? true : false}
			hasvalue={hasvalue}
		>
			<textarea
				placeholder={getPlaceholder(label, required)}
				maxLength={maxLength}
				required={required}
				{...field}
				rows={rows}
			/>
			<Error touched={meta.touched} error={meta.error} />
		</StyledFieldWrapper>
	);
};

export default TextArea;

interface TextAreaProps extends FieldType {
	className?: string;
	maxLength?: number;
	autoComplete?: string;
	disabled?: boolean;
	rows?: number;
}
