import styled, { css } from "styled-components";

const StyledProgressIndicator = styled.div`
	${(p) => css`
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 0.5rem;

		* {
			font-size: ${p.theme.xxSmallSize};
			color: ${p.theme.colorCopyLightLight};
		}
		.step-count {
			text-transform: uppercase;
			color: #2f5194;
			.active-step {
				font-weight: 600;
				text-decoration: underline;
				color: #2f5194;
			}
		}

		.progress-bar {
			min-width: 350px;
			height: 25px;
			border: solid 2px white;
			background: #92b8dd;
			border-radius: 10.5px;
			overflow: hidden;
		}
	`}
`;

const PercentageBar = styled.div<{ width: number }>`
	${(p) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		width: ${`${p.width}%`};
		min-width: 25px;
		background: ${p.theme.colorPrimary};
		border-radius: 10.5px 0 0 10.5px;
		height: 100%;
		padding: 3px;
		font-size: ${p.theme.xxxSmallSize};
		line-height: ${p.theme.xxxSmallSize};

		${p.width === 0 &&
		css`
			padding: 0;
			margin-left: 3px;
			min-width: 0;
			justify-content: unset;
		`}
	`};
`;

const ProgressIndicator = (props: ProgressIndicatorProps) => {
	const { activeStep, totalSteps } = props;
	const percentage = Math.round((activeStep / totalSteps) * 100);
	return (
		<StyledProgressIndicator>
			<span className="step-count">
				Step <span className="active-step">{activeStep + 1}</span> of{" "}
				{totalSteps + 1}
			</span>
			<div className="progress-bar">
				<PercentageBar width={percentage}> {percentage}%</PercentageBar>
			</div>
		</StyledProgressIndicator>
	);
};
interface ProgressIndicatorProps {
	activeStep: number;
	totalSteps: number;
}
export default ProgressIndicator;
