import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
  UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css, useTheme } from "styled-components";
import {
  countyOptions,
  householdSizeOptions,
  incomeThresholdMap,
  povertyGuidelineOptions,
} from "../FormSchema/formInitialValues";
import StyledLegend, {
  BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import StyledList from "../../../components/Lists/Lists";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    affidavitA: {
      applicantName: "",
      todaysDate: "",
      signature: "",
      legalGuardian: "",
      legalGuardianRelationship: "",
    },
  },
  validation: Yup.object().shape({
    affidavitA: Yup.object().shape({
      applicantName: Yup.string().required(requiredWarning),
      todaysDate: Yup.string()
        .required(requiredWarning)
        .test({
          name: "today",
          message: "Must select today's date",
          test(value, ctx) {
            if (value) {
              return new Date(value).getDate() === new Date().getDate();
            } else return true;
          },
        }),
      signature: Yup.string().required(requiredWarning),
    }),
  }),
};

const AffidavitA = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { formId } = useFormContext(); // Get formId from context

  return (
    <>
      <p>
        You have indicated that you have filed a Federal Income Tax form for
        2023. <br />
        <br />
        Attach a copy of the prior year's filed Federal Income Tax forms,
        including certification form if filed electronically, for each household
        member who had income (except as excluded in the{" "}
        <b>Household Size & Income Verification</b> section) AND complete and
        sign Affidavit A:
      </p>
      <div>
        <BlueLegendHeading>
          By my signature, I swear or affirm that the attached tax form is a
          true reflection of my income for calendar year 2024, and is a correct
          copy of the form provided to the Internal Revenue Service (IRS).
        </BlueLegendHeading>

        <p>
          I certify that the foregoing answers are true and accurate to the best
          of my knowledge and belief. I also acknowledge that NMMIP may verify
          this information with state agencies and other sources.
        </p>
      </div>

      <FormGrid>
        <FormGrid className="flex-col flex-1">
          <TextField
            className="!flex-none"
            type="text"
            name="affidavitA.applicantName"
            label="Name of Applicant"
            required
          />
          <DateField
            name="affidavitA.todaysDate"
            label="Today's Date"
            placeholder="Select Today's Date"
            required
          />
        </FormGrid>
        <Signature
          name="affidavitA.signature"
          label="Signature of Applicant"
          signatureType="AffidavitASignature"
          required
        />
      </FormGrid>

      <p>
        A parent/legal guardian/personal representative must sign if the
        applicant is under 18 years of age or legally incompetent.
      </p>

      <FormGrid>
        <TextField
          type="text"
          name="affidavitA.legalGuardian"
          label="Name of parent/legal guardian/personal representative"
          autoComplete="off"
        />
        <TextField
          type="text"
          name="affidavitA.legalGuardianRelationship"
          label="Relationship to Applicant"
          autoComplete="off"
        />
      </FormGrid>

      <Modal
        modalLabel="Federal Income Tax forms and Certification Form"
        buttonLabel="Upload Documentation"
      >
        <NoticeBanner bannerstyle={ColorStyle.Primary}>
          Attach a copy of the prior year's filed Federal Income Tax forms and
          certification forms for each household member who had income.
        </NoticeBanner>
        <NoticeBanner bannerstyle={ColorStyle.Secondary}>
          If you don't have the documentation electronically, please mail it to
          NMMIP upon completing this application.
        </NoticeBanner>
        <FileUpload
          name="documentation.incomeTaxForms"
          label="Upload Files"
          documentationType="incomeTaxForms"
        />
      </Modal>

      <div className="max-w-[500px]">
        <UploadedFileList
          formId={formId}
          fieldName={"documentation.incomeTaxForms"}
          documentationType="incomeTaxForms"
        />
      </div>
    </>
  );
};

export default AffidavitA;
