import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
  UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css, useTheme } from "styled-components";
import {
  countyOptions,
  householdSizeOptions,
  incomeThresholdMap,
  povertyGuidelineOptions,
} from "../FormSchema/formInitialValues";
import StyledLegend, {
  BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import StyledList from "../../../components/Lists/Lists";
import TextArea from "../../../components/FormFields/TextArea";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    affidavitC: {
      currentAnnualHouseholdIncome: "",
      explanationForIncomeDifference: "",
      signature: "",
    },
  },
  validation: Yup.object().shape({
    affidavitC: Yup.object().shape({
      currentAnnualHouseholdIncome: Yup.string().required(requiredWarning),
      explanationForIncomeDifference: Yup.string().required(requiredWarning),
      signature: Yup.string().required(requiredWarning),
    }),
  }),
};

const AffidavitC = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { formId } = useFormContext(); // Get formId from context

  return (
    <>
      <TextField
        className="max-w-[450px]"
        type="currency"
        name="affidavitC.currentAnnualHouseholdIncome"
        label="Current Annual Household Income Total"
        required
      />
      <TextArea
        className="max-w-[700px]"
        name="affidavitC.explanationForIncomeDifference"
        label="Reason for Difference between most recent Tax Filing and Current Household Income"
        required
        rows={6}
      />
      <div className="flex flex-col gap-[1rem]">
        <h2>Signature</h2>
        <p>
          By my signature, I swear or affirm that my current income for calendar
          year 2024 is as noted above.
        </p>
        <Signature
          name={`affidavitC.signature`}
          label="Signature of Applicant"
          signatureType="AffidavitCSignature"
          required
        />
      </div>

      <Modal
        modalLabel="Federal Income Tax Return"
        buttonLabel="Upload Documentation"
      >
        <NoticeBanner bannerstyle={ColorStyle.Primary}>
          Attach a copy of your Federal Income Tax return.
        </NoticeBanner>
        <NoticeBanner bannerstyle={ColorStyle.Secondary}>
          If you don't have the documentation electronically, please mail it to
          NMMIP upon completing this application.
        </NoticeBanner>
        <FileUpload
          name="documentation.incomeTaxReturn"
          label="Upload Files"
          documentationType="incomeTaxReturn"
        />
      </Modal>

      <div className="max-w-[500px]">
        <UploadedFileList
          formId={formId}
          fieldName={"documentation.incomeTaxReturn"}
          documentationType="incomeTaxReturn"
        />
      </div>
    </>
  );
};

export default AffidavitC;
